import React, { Component } from 'react';
import { loadProjects } from './requests';
import FooterSection from "./FooterSection";

export class ProjectManagementProcess extends Component {

  constructor(props) {
    super(props);
    this.state = {Projects: []};
  }

  async componentDidMount() {
    const Projects = await loadProjects();
    this.setState({Projects});
  }

  render() {

    return (
      <div>
          <div className="main-container">
              <section className="space-lg pt-0">
                  <img alt="" src="assets/img/graphic-bg-clouds-1.png" className="bg-image"/>
                  <div className="container">
                      <div className="row justify-content-center space-lg">
                          <div className="col-12 col-md-9 col-lg-8 text-center">
                              <h1 className="display-3">Project Process</h1>
                              <span className="lead">
                                A robust suite of styled components, powered by Bootstrap 4.
 Take the design of your website or webapp up a notch.
                            </span>
                              <a href="/" className="btn btn-success btn-lg">Get started now</a>
                          </div>
                      </div>
                      <div className="row justify-content-center">
                          <div className="col-12 col-lg-8">
                              <div className="video-cover rounded box-shadow">
                                  <img alt="" src="assets/img/photo-man-whiteboard.jpg" className="bg-image"/>
                                  <div className="video-play-icon">
                                      <i className="icon-controller-play"></i>
                                  </div>
                                  <div className="embed-responsive embed-responsive-16by9">
                                      <iframe title="Youtube Video"
                                              className="embed-responsive-item"
                                              data-src="https://www.youtube.com/embed/oYqvpjKJZkU?autoplay=1&amp;mute=1&amp;showinfo=0&amp;rel=0"
                                              allowFullScreen></iframe>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </section>
              <section>
                  <div className="container">
                      <div className="row justify-content-center text-center section-intro">
                          <div className="col-12 col-md-9 col-lg-8">
                              <span className="title-decorative">Welcome Home</span>
                              <h2 className="display-4">Greatly simplified workflow</h2>
                              <span className="lead">An opportunity to introduce the major benefits of your product and set the scene for what's to come</span>

                          </div>
                      </div>
                      <ul className="feature-list feature-list-lg">
                          <li className="row justify-content-around align-items-center">
                              <div className="col-12 col-md-6 col-lg-5">
                                  <img alt="" src="assets/img/photo-woman-laptop.jpg"
                                       className="img-fluid rounded"/>
                              </div>
                              <div className="col-12 col-md-6 col-lg-5">
                                  <h5>Set your phasers to stun</h5>
                                  <p>
                                      A paragraph, from the Greek paragraphos, "to write beside" or "written beside", is
                                      a self-contained unit of a discourse in writing dealing with a particular point or
                                      idea. A paragraph consists of one or more sentences.
                                  </p>
                                  <a href="/">More about Phasers &rsaquo;</a>
                              </div>
                          </li>
                          <li className="row justify-content-around align-items-center">
                              <div className="col-12 col-md-6 col-lg-5 order-lg-2">
                                  <img alt="" src="assets/img/photo-team-desk.jpg" className="img-fluid rounded"/>
                              </div>
                              <div className="col-12 col-md-6 col-lg-5 order-lg-1">
                                  <h5>Let the world know</h5>
                                  <p>
                                      A paragraph, from the Greek paragraphos, "to write beside" or "written beside", is
                                      a self-contained unit of a discourse in writing dealing with a particular point or
                                      idea. A paragraph consists of one or more sentences.
                                  </p>
                                  <a href="/">More about Team Collaboration &rsaquo;</a>
                              </div>
                          </li>
                      </ul>
                  </div>
              </section>
              <section>
                  <div className="container">
                      <div className="row justify-content-center text-center section-intro">
                          <div className="col-12 col-md-9 col-lg-8">
                              <span className="title-decorative">Welcome Home</span>
                              <h2 className="display-4">Greatly simplified workflow</h2>
                              <span className="lead">An opportunity to introduce the major benefits of your product and set the scene for what's to come</span>

                          </div>
                      </div>
                      <ul className="row feature-list">
                          <li className="col-12 col-md-4">
                              <i className="icon-fingerprint h1 text-teal"></i>
                              <h5>Armed and fabulous</h5>
                              <p>
                                  A self-contained unit of a discourse in writing dealing with a particular point or
                                  idea. A paragraph consists of one or more sentences.
                              </p>
                          </li>
                          <li className="col-12 col-md-4">
                              <i className="icon-tablet-mobile-combo h1 text-purple"></i>
                              <h5>Content first</h5>
                              <p>
                                  A paragraph, from the Greek paragraphos is a self-contained unit of a discourse in
                                  writing dealing with a particular point or idea.
                              </p>
                          </li>
                          <li className="col-12 col-md-4">
                              <i className="icon-folder-music h1 text-red"></i>
                              <h5>All in one place</h5>
                              <p>
                                  A self-contained unit of a discourse in writing dealing with a particular point or
                                  idea. A paragraph consists of one or more sentences.
                              </p>
                          </li>
                      </ul>
                      <div className="row justify-content-center text-center section-outro">
                          <div className="col-lg-4 col-md-5">
                              <h6>Introducing a new way</h6>
                              <p>An opportunity to introduce the major benefits of your product and set the scene for
                                  what's to come</p>
                              <a href="/">View more features &rsaquo;</a>
                          </div>
                      </div>
                  </div>
              </section>
              <section>
                  <div className="container">
                      <div className="row align-items-center justify-content-between section-intro">
                          <div className="col-12 col-lg-5 text-center text-lg-left section-intro">
                              <span className="title-decorative">Simple Pricing</span>
                              <h3 className="display-4">No hidden fees, please.</h3>
                              <span className="lead">
                                A robust suite of styled components, powered by Bootstrap 4.
 Take the design of your website or webapp up a notch.
                            </span>
                          </div>
                          <div className="col-12 col-lg-6 row no-gutters card-group">
                              <div className="card pricing card-lg bg-dark col-lg-6">
                                  <div className="card-body">
                                      <h5>Unlimited</h5>
                                      <span className="display-3">$9</span>
                                      <span className="text-small">per user / month</span>
                                      <span className="h6">Includes:</span>
                                      <ul className="list-unstyled">
                                          <li>
                                              Unlimited projects
                                          </li>
                                          <li>
                                              Unlimited repositories
                                          </li>
                                          <li>
                                              Unlimited collaborators
                                          </li>
                                      </ul>
                                      <a href="/" className="btn btn-lg btn-success">Sign up now</a>
                                  </div>
                              </div>
                              <div className="card pricing card-lg col-lg-6">
                                  <div className="card-body">
                                      <h5>Basic</h5>
                                      <span className="display-3">Free</span>
                                      <span className="text-small">per user / month</span>
                                      <span className="h6">Includes:</span>
                                      <ul className="list-unstyled">
                                          <li>
                                              1 Active project
                                          </li>
                                          <li>
                                              2 Repositories
                                          </li>
                                          <li>
                                              2 Collaborators
                                          </li>
                                      </ul>
                                      <a href="/" className="btn btn-lg btn-outline-primary">Sign up now</a>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div className="row mb-5">
                          <div className="col-12 text-center">
                              <h4>Don’t worry, you’ll be keeping good company</h4>
                              <ul className="list-inline list-inline-large">
                                  <li className="list-inline-item">
                                      <img alt="" className="logo" src="assets/img/logo-paypal.png"/>
                                  </li>
                                  <li className="list-inline-item">
                                      <img alt="" className="logo" src="assets/img/logo-slack.png"/>
                                  </li>
                                  <li className="list-inline-item">
                                      <img alt="" className="logo" src="assets/img/logo-invision.png"/>
                                  </li>
                                  <li className="list-inline-item">
                                      <img alt="" className="logo" src="assets/img/logo-intercom.png"/>
                                  </li>
                              </ul>
                          </div>
                      </div>
                      <div className="row justify-content-center">

                          <div className="col-12 col-md-6 col-lg-5">
                              <div className="media">
                                  <img alt="" src="assets/img/avatar-male-1.jpg" className="avatar"/>
                                  <div className="media-body">
                                      <p className="mb-1">
                                          “Let’s get one thing straight, this theme’s a straight-up winner. No posers
                                          here, just beautiful design and code.”
                                      </p>
                                      <small>Daniel Cameron</small>
                                  </div>
                              </div>
                          </div>

                          <div className="col-12 col-md-6 col-lg-5">
                              <div className="media">
                                  <img alt="" src="assets/img/avatar-female-1.jpg" className="avatar"/>
                                  <div className="media-body">
                                      <p className="mb-1">
                                          “With Wingman, we were able to turn out a stunning landing page and compelling
                                          MVP for our SaaS web app in no time.”
                                      </p>
                                      <small>Caitlyn Halsy, Bench</small>
                                  </div>
                              </div>
                          </div>

                      </div>
                  </div>
              </section>
              <section className="bg-secondary text-center space-lg pb-0">
                  <div className="container">
                      <div className="row">
                          <div className="col-12">
                              <h3 className="h1">Don't even bother waiting</h3>
                              <a href="/" className="btn btn-lg btn-success">Start free trial now</a>
                          </div>
                      </div>
                      <div className="row">
                          <div className="col-12">
                              <img alt="" className="w-100 opacity-60" src="assets/img/graphic-bg-city.svg"/>
                          </div>
                      </div>
                  </div>
              </section>

              <FooterSection/>

          </div>


      </div>
    );
  }
}

