import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import {isLoggedIn, logout} from './auth';
import { LoginForm } from './LoginForm';
import { ProjectBoard } from './ProjectBoard';
import { ProjectDetail } from './ProjectDetail';
import { ProjectForm } from './ProjectForm';
import { CompanyBoard } from './CompanyBoard';
import { CompanyDetail } from './CompanyDetail';

import { NavBar } from './NavBar';
import {HomePage2} from "./HomePage2";
import {DeliverableDetail} from "./DeliverableDetail";
import ProjectFullDetail from "./ProjectFullDetail";
import {ClientFaqPage} from "./ClientFaqPage";
import {MarkdownTest} from "./MarkdownTest";

import { TransparencyPage } from "./Transparency";
import { DevelopmentProcessPage } from "./DevelopmentProcessPage";
import { ShowCasePage } from "./ShowCasePage";

import {AboutPage} from "./AboutPage";
import {ProjectFullVisualDetail} from "./ProjectFullVisualDetail";
import {ProjectManagementProcess} from "./ProjectManagementProcess";
import {MarkdownTest2} from "./MarkdownTest2";

import ModalManager from './components/Modals/ModalManager';
import DashBoardPage2 from "./DashBoardPage2";
import {ProjectQuoteDetails} from "./ProjectQuoteDetails";

export class App extends Component {
  constructor(props) {
    super(props);
    this.state = {loggedIn: isLoggedIn()};
  }

  handleLogin() {
    this.setState({loggedIn: true});
    this.router.history.push('/dashboard');
  }

  handleLogout() {
    logout();
    this.setState({loggedIn: false});
    this.router.history.push('/');
  }

  render() {
    const {loggedIn} = this.state;

    // const styles = {
    //     paperContainer: {
    //         backgroundImage: `url(${Image})`
    //     }
    // };

    return (
      <Router ref={(router) => this.router = router}>
        {/*<div style={styles.paperContainer}>*/}
        <div>
          <ModalManager/>
          <NavBar loggedIn={loggedIn} onLogout={this.handleLogout.bind(this)} />
              <Switch>
                <Route exact path="/" component={HomePage2} />

                <Route exact path="/transparency" component={TransparencyPage} />
                <Route exact path="/developmentprocess" component={DevelopmentProcessPage} />
                <Route exact path="/showcase" component={ShowCasePage} />
                <Route exact path="/about" component={AboutPage} />
                <Route exact path="/projectmanagementprocess" component={ProjectManagementProcess} />
                <Route exact path="/markdowntest" component={MarkdownTest2} />

                <Route exact path="/dashboard" component={DashBoardPage2} />
                <Route exact path="/projects" component={ProjectBoard} />
                <Route exact path="/companies/" component={CompanyBoard} />
                <Route exact path="/markdowntest/" component={MarkdownTest} />
                <Route path="/companies/:companyId" component={CompanyDetail} />
                <Route exact path="/Projects/new" component={ProjectForm} />

                <Route path="/Projects/:ProjectId" component={ProjectDetail} />
                <Route path="/ProjectsQuoteDetails/:ProjectId" component={ProjectQuoteDetails} />
                <Route path="/ProjectsFullDetails/:ProjectId" component={ProjectFullDetail} />
                <Route path="/ProjectsFullVisualDetails/:ProjectId" component={ProjectFullVisualDetail} />

                <Route path="/Deliverables/:DeliverableId" component={DeliverableDetail} />
                <Route exact path="/clientfaq" component={ClientFaqPage} />
                <Route exact path="/login" render={() => <LoginForm onLogin={this.handleLogin.bind(this)} />} />
              </Switch>
        </div>
      </Router>
    );
  }
}
