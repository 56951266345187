import React from 'react'
// import { Field, reduxForm } from 'redux-form'
import {Formik} from "formik";
import {newDeliverable} from "../../../actions";
import {connect} from "react-redux";

class DeliverableNewForm extends React.Component {

    /* This is the deliverable so task needs updating to deliverable */
    constructor(props) {
        super(props);
        this.state = {
            projectId: this.props.currentModals.modalProps.projectId,
        };
    }

    render() {
        return (

            <div style={{width: '600px'}}>

                <Formik
                    initialValues={{
                        title: "",
                        description: "",
                    }}

                    onSubmit={values => {
                        console.log("-----", JSON.stringify(this.state))
                        this.props.dispatch(
                            newDeliverable(
                                values.title,
                                values.description,
                                this.state.projectId,
                            )
                        )
                        ;
                    }}

                    // validate={ values => {
                    //     let errors= {}
                    //     if (!values.title) {
                    //         errors.title = "please enter a name"
                    //     }
                    //     if (!values.duration) {
                    //         errors.duration= "please enter a duration"
                    //     }
                    //     return errors
                    // }}

                >
                    {
                        ({handleSubmit, handleChange, values, errors, handleBlur, touched}) => (
                            <form onSubmit={handleSubmit}>

                                <div className="form-group">
                                    <label htmlFor="exampleInputEmail1">Deliverable Title</label>
                                    <input
                                        className="form-control"
                                        onChange={handleChange}
                                        value={values.title}
                                        type="text"
                                        name="title"
                                        placeholder="Enter your deliverable title"
                                        onBlur={handleBlur}
                                    />
                                    <small id="emailHelp" className="form-text text-muted">A Deliverable title</small>
                                </div>

                                <div className="form-group">
                                    <label htmlFor="exampleInputEmail1">Description</label>
                                    <textarea
                                        className="form-control textarea"
                                        onChange={handleChange}
                                        value={values.description}
                                        name="description"
                                        placeholder="Enter your description"
                                        onBlur={handleBlur}
                                    />
                                    <small id="emailHelp" className="form-text text-muted">A nice description
                                    </small>
                                </div>

                                <div className="row">
                                    <div className="col-sm-6">
                                        <button type="submit" className="btn btn-primary pull-right">Save</button>
                                        &nbsp;
                                        <button onClick={() => { this.props.onCancel() }} className="btn btn-secondary pull-right">Cancel</button>
                                    </div>
                                </div>

                                {/*projectId: { projectId }*/}

                                {
                                    errors.title &&
                                    <div style={{color: 'red'}}>
                                        {errors.title}
                                    </div>
                                }
                            </form>
                        )

                    }
                </Formik>

            </div>
        )
    }

}

const mapStateToProps = (state) => ({
    currentModals: state.modals
});

export default connect(mapStateToProps, null)(DeliverableNewForm);