import React from 'react'
import {connect} from 'react-redux';
import {loadDeliverable} from "../../../requests";
import {Formik} from "formik/dist/index";
import {deleteDeliverable, saveDeliverable} from "../../../actions";

class DeliverableEditComponent extends React.Component {

    /* This is the deliverable so task needs updating to deliverable */
    constructor(props) {
        super(props);
        this.state = {
            deliverableId: this.props.currentModals.modalProps.deliverableId,
            deliverable: []
        };
    }

    async componentDidMount() {
        const aDeliverable = await loadDeliverable(this.state.deliverableId);
        this.setState({deliverable: aDeliverable});
        console.log("did mount")
    }

    onDelete() {
        this.props.dispatch(
            deleteDeliverable(
                this.state.deliverableId,
            ));
    }

    render() {

        if (this.state.deliverable.length === 0) {
            return null
        }

        return (
            <div style={{width: '600px'}}>

                <Formik
                    initialValues={{
                        title: this.state.deliverable.title,
                        description: this.state.deliverable.description,
                        projectId: this.state.deliverable.project.id,
                    }}

                    onSubmit={values => {
                        console.log("-----", JSON.stringify(this.state))
                        this.props.dispatch(
                            saveDeliverable(
                                this.state.deliverableId,
                                values.projectId,
                                values.title,
                                values.description,
                            )
                        )
                        ;
                    }}

                    // validate={ values => {
                    //     let errors= {}
                    //     if (!values.title) {
                    //         errors.title = "please enter a name"
                    //     }
                    //     if (!values.duration) {
                    //         errors.duration= "please enter a duration"
                    //     }
                    //     return errors
                    // }}

                >
                    {
                        ({handleSubmit, handleChange, values, errors, handleBlur, touched}) => (
                            <form onSubmit={handleSubmit}>

                                <div className="form-group">
                                    <label htmlFor="exampleInputEmail1">Deliverable Title</label>
                                    <input
                                        className="form-control"
                                        onChange={handleChange}
                                        value={values.title}
                                        type="text"
                                        name="title"
                                        placeholder="Enter your deliverable title"
                                        onBlur={handleBlur}
                                    />
                                    <small id="emailHelp" className="form-text text-muted">A Deliverable title</small>
                                </div>

                                <div className="form-group">
                                    <label htmlFor="exampleInputEmail1">Description</label>
                                    <textarea
                                        className="form-control textarea"
                                        onChange={handleChange}
                                        value={values.description}
                                        name="description"
                                        placeholder="Enter your description"
                                        onBlur={handleBlur}
                                    />
                                    <small id="emailHelp" className="form-text text-muted">A nice description
                                    </small>
                                </div>

                                <div className="form-group">
                                    <label htmlFor="exampleInputEmail1">Project Id</label>
                                    <input
                                        className="form-control"
                                        onChange={handleChange}
                                        value={values.projectId}
                                        type="text"
                                        name="projectId"
                                        placeholder="Enter your Project Id"
                                        onBlur={handleBlur}
                                    />
                                    <small id="emailHelp" className="form-text text-muted">Don't mess with</small>
                                </div>

                                <div className="row">
                                    <div className="col-sm-6">
                                        <button type="submit" className="btn btn-primary pull-right">Save</button>
                                        &nbsp;
                                        <button onClick={() => {
                                            this.props.onCancel()
                                        }} className="btn btn-secondary pull-right">Cancel
                                        </button>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="text-right">
                                            <button type="button" className="btn btn-danger" onClick={() => {
                                                this.onDelete()
                                            }}>Delete
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                {
                                    errors.title &&
                                    <div style={{color: 'red'}}>
                                        {errors.title}
                                    </div>
                                }

                                <br/>
                                Deliverable Id: {this.state.deliverable.id}
                            </form>

                        )

                    }
                </Formik>

            </div>
        )

    };

}

const mapStateToProps = (state) => ({
    currentModals: state.modals
});


export default connect(mapStateToProps, null)(DeliverableEditComponent);