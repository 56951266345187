import React, { Component, Fragment } from 'react';
// import TextHighlight from "./TextHighlight";
import HighlightText from "./HighlightText";

export class DeliverableList extends Component {

  renderDeliverable(Deliverable) {
    const title = Deliverable.title;
    const workLeft = Deliverable.total - Deliverable.totalcompleted;

    const outstanding = workLeft.toFixed(1) + ' days ';

    return (
        <Fragment>
            <tr key={Deliverable.id} className="text-small">
                <th scope="row">
                    <a href={`/Deliverables/${Deliverable.id}`} className="d-flex align-items-center">
                        <span className="text-body">
                            <HighlightText showCheck={true} showText={true} completed={ ( Deliverable.totalcompleted - Deliverable.total ) === 0 }>{title}</HighlightText>
                        </span>
                    </a>
                </th>
                <td>
                    { ( Deliverable.totalcompleted - Deliverable.total ) !== 0 &&
                    <div>
                        {outstanding}
                    </div>
                    }
                </td>
                {/*<td>*/}
                {/*    <div className="dropdown d-inline-block">*/}
                {/*        <button*/}
                {/*            className="btn btn-sm btn-outline-primary dropdown-toggle dropdown-toggle-no-arrow"*/}
                {/*            type="button" id="dropdownMenuButton-10" data-toggle="dropdown"*/}
                {/*            aria-haspopup="true" aria-expanded="false">*/}
                {/*            <i className="icon-dots-three-horizontal"></i>*/}
                {/*        </button>*/}
                {/*        <div className="dropdown-menu dropdown-menu-sm"*/}
                {/*             aria-labelledby="dropdownMenuButton">*/}
                {/*            <a className="dropdown-item" href="">Download</a>*/}
                {/*            <a className="dropdown-item" href="">Share</a>*/}
                {/*            <a className="dropdown-item" href="">Comment</a>*/}
                {/*            <div className="dropdown-divider"></div>*/}
                {/*            <a className="dropdown-item" href="">Delete</a>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</td>*/}
            </tr>

        </Fragment>
    );
  }

  render() {
        const {Project} = this.props;

        return (
        <Fragment>
            {/*<div className="main-container">*/}
            {/*    <section className="flush-with-above">*/}
            {/*        <div className="container">*/}
            {/*            <div className="row justify-content-between">*/}
            {/*                <div className="col-12 col-md-12 col-lg-12">*/}
                                <table className="table align-items-center">
                                    <thead>
                                        <tr>
                                            <th scope="col">Deliverables</th>
                                            <th scope="col">Outstanding</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {Project.deliverables &&
                                         Project.deliverables.map(this.renderDeliverable.bind(this))
                                        }
                                        <tr>
                                            <td>
                                                <span passName="text-body">
                                                    Totals
                                                </span>
                                            </td>
                                            <td>
                                                <span className="text-body">
                                                    {Project.totalCompleted.toFixed(1)} / {Project.total.toFixed(1)}
                                                </span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>

            {/*                </div>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </section>*/}
            {/*</div>*/}
         </Fragment>
     );
  }

}
