import React from 'react';
import {loadCompany, loadProjects} from './requests';
import FooterSection from "./FooterSection";
import {ProjectListDash} from "./ProjectListDash";
import {Pie, PieChart, ResponsiveContainer, Tooltip} from "recharts";
import {ProjectList} from "./ProjectList";
import {connect} from "react-redux";
import {Button} from "reactstrap";
import {toastr} from "react-redux-toastr";
import axios from "axios";
import {isUserLevel} from "./auth";
import {getURLforProductionOrDev} from "./library/server";

class DashBoardPage2 extends React.Component {

    constructor(props) {
        super(props);
        this.state = {company: null, projects: null};
    }

    hideCompleted(bShow) {
        this.props.dispatch({
            type: 'UPDATE_SHOW_COMPLETED',
            payload: !this.props.currentFilters.data[0]["hideCompleted"]
        });
    }

    calcData(Projects, Filter) {

        let total = 15; //Math.abs(Project.totalCompleted);
        let totalCompleted = 3; //Math.abs(Project.total - Project.totalCompleted);
        let totalLeft = total = totalCompleted

        let filteredProjects = []

        filteredProjects = Projects.filter(elem => {
            return elem.folder === Filter;
        })

        if (filteredProjects.length === 0) {
            total = 0
            totalCompleted = 0
        } else {
            total = (filteredProjects.reduce(
                (accumulator, currentValue) => accumulator + currentValue.total,
                0
            ))
            totalCompleted = (filteredProjects.reduce(
                (accumulator, currentValue) => accumulator + currentValue.totalCompleted,
                0
            ))
            totalLeft = (total - totalCompleted)
        }

        return [
            {name: 'Left', value: totalLeft},
            {name: 'Completed', value: totalCompleted, fill: "#ff0000"}
        ];

    }

    async componentDidMount() {
        const companyId = "HJRa-DOuG";
        const company = await loadCompany(companyId);
        this.setState({company});
        const projects = await loadProjects();
        this.setState({projects});
    }

    getZip() {

        axios({
            url: getURLforProductionOrDev() + '/datafile',
            method: 'post',
            responseType: 'blob',
            data: {}
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'data.zip');
            document.body.appendChild(link);
            link.click();
        });

    }

    exportMessage() {
        toastr.warning("Processing...");
        this.getZip()
    }

    render() {
        const {company, projects} = this.state;

        if (!company) {
            return null;
        }
        if (!projects) {
            return null;
        }

        let bHideCompleted = this.props.currentFilters.data[0]["hideCompleted"]

        console.log(JSON.stringify(company))

        let results = this.calcData(projects, "inbox")

        const projectDays = results

        let projectDaysLeft = results.find(o => o.name === 'Left').value.toFixed(1);

        return (

            <div className="nav-container">
                <div className="main-container">
                    <nav aria-label="breadcrumb" className="bg-primary text-white">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item">
                                            <a href="/dashboard"> Home </a>
                                        </li>
                                        <li className="breadcrumb-item active" aria-current="page">
                                            {company.name}
                                        </li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </nav>
                </div>

                <section className="bg-white space-sm">
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <h1>Dashboard - {company.name}</h1>
                            </div>
                        </div>
                    </div>
                </section>

                <div className="main-container">
                    <section className="flush-with-above">
                        <div className="container">
                            <div className="row justify-content-between">

                                {/*<div className="col-12">*/}
                                {/*    <div className="card-header d-flex justify-content-between">*/}
                                {/*        <div>*/}
                                {/*            <span className="h3"><i*/}
                                {/*                className="fas fa-bullhorn"></i>&nbsp;Open Customer Queries</span>*/}
                                {/*        </div>*/}
                                {/*    </div>*/}
                                {/*    <table className="table align-items-center">*/}
                                {/*        <tbody>*/}
                                {/*        <TaskList showHheader={false}*/}
                                {/*                  Deliverable={company.projects[0].deliverables[1]}/>*/}
                                {/*        </tbody>*/}
                                {/*    </table>*/}
                                {/*</div>*/}

                                <div className="col-12 col-md-4">
                                    <div className="card">
                                        <div className="card-header d-flex justify-content-between">
                                            <div>
                                                <span className="h6">Inbox Project Details</span>
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <ResponsiveContainer width='100%' height='100%' aspect={4.0 / 4.0}>
                                                <PieChart padding={{top: 20, right: 20, left: 20, bottom: 20}}>
                                                    <Pie data={projectDays} innerRadius='20%' outerRadius='90%'
                                                         fill="#82ca9d"/>
                                                    <Tooltip/>
                                                </PieChart>
                                            </ResponsiveContainer>
                                            <span className="h6">
                                                    {projectDaysLeft}d left
                                            </span>
                                        </div>
                                        <div className="card-header d-flex justify-content-between">
                                        </div>
                                    </div>

                                    <div className="card">
                                        <div className="card-header d-flex justify-content-between">
                                            <div>
                                                <span className="h6">Filters</span>
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <Button type="button" onClick={() => {
                                                this.hideCompleted(true)
                                            }}>

                                                {(bHideCompleted) &&
                                                <div>
                                                    Hiding
                                                </div>
                                                }

                                                {(!bHideCompleted) &&
                                                <div>
                                                    Showing
                                                </div>
                                                }

                                                Completed Tasks
                                            </Button>
                                        </div>
                                    </div>

                                    {(isUserLevel() === "1") &&
                                    <div className="card">
                                        <div className="card-header d-flex justify-content-between">
                                            <div>
                                                <span className="h6">Export</span>
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <Button type="button" onClick={() => {
                                                this.exportMessage()
                                            }}>
                                                Export Datafiles
                                            </Button>
                                        </div>
                                    </div>
                                    }
                                </div>

                                <div className="col-12 col-md-8 col-lg-8">
                                    <table className="table table-borderless align-items-center">
                                        <tbody>
                                        <ProjectListDash projects={projects} filterfolder="inbox"
                                                         currentFilters={this.props.currentFilters}/>
                                        </tbody>
                                    </table>
                                </div>

                                <div className="col-12">
                                    <table className="table table-borderless align-items-center">
                                        <tbody>
                                        <ProjectList Projects={company.projects} filterfolder=""
                                                     currentFilters={this.props.currentFilters}/>
                                        <ProjectList Projects={company.projects} filterfolder="pending"
                                                     currentFilters={this.props.currentFilters}/>
                                        <ProjectList Projects={company.projects} filterfolder="archive"
                                                     currentFilters={this.props.currentFilters}/>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>

                <FooterSection/>

            </div>

        )
    }
}


const mapStateToProps = (state) => ({
    currentFilters: state.filters
});

export default connect(mapStateToProps)(DashBoardPage2);
