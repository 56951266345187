import React from 'react';
import { connect } from 'react-redux';
import TestModal from './TestModal';
import NewTaskModal from "./NewTaskModal";
import EditTaskModal from "./EditTaskModal";
import NewDeliverableModal from "./NewDeliverableModal";
import EditDeliverableModal from "./EditDeliverableModal";
import EditProjectModal from "./EditProjectModal";

const modalLookup = {
    TestModal,
    EditTaskModal,
    NewTaskModal,
    NewDeliverableModal,
    EditDeliverableModal,
    EditProjectModal
};

const mapState = (state) => ({
    currentModal: state.modals
});

const ModalManager = ({currentModal}) => {
    console.log("modalmanager");
    let renderedModal;
    if (currentModal) {
        console.log("yes");
        const {modalType, modalProps} = currentModal;
        const ModalComponent = modalLookup[modalType];
        renderedModal = <ModalComponent {...modalProps} />
    }
    console.log("no");
    return <span>{renderedModal}</span>
};

export default connect(mapState)(ModalManager);