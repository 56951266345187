import React from 'react';
import {Link} from 'react-router-dom';
import {loadProject} from './requests';
import {Tooltip, PieChart, Pie} from "recharts";
import DeliverableFullList from "./DeliverableFullList";

import {connect} from "react-redux";
import {openModal} from "./components/Modals/modalActions";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPencilAlt} from "@fortawesome/free-solid-svg-icons";

const actions = { openModal }

class ProjectFullDetail extends React.Component {

    constructor(props) {
        super(props);
        this.state = {project: null};
    }

    async componentDidMount() {
        const {ProjectId} = this.props.match.params;
        const project = await loadProject(ProjectId);
        this.setState({project});
    }

    calcData(project) {
        let total = Math.abs(project.totalCompleted);
        let totalCompleted = Math.abs(project.total - project.totalCompleted);
        return [
            {name: 'Group A', value: total},
            {name: 'Group B', value: totalCompleted, fill: "#ff0000"}
        ];
    }

    timeLeft(project) {
        return ((project.total - project.totalCompleted).toFixed(1));
    }

    render() {
        const {project} = this.state;
        if (!project) {
            return null;
        }

        const { openModal } = this.props;

        return (
            <div>

                <div className="main-container">
                    <nav aria-label="breadcrumb" className="bg-primary text-white">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><a href="/">Home</a>
                                        </li>
                                        <li className="breadcrumb-item"><a href="/projects">Projects</a>
                                        </li>
                                        <li className="breadcrumb-item active" aria-current="page">{project.title}</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </nav>
                </div>

                <section className="bg-white space-sm">
                    <div className="container">
                        <div className="row justify-content-between">
                            <div className="col">
                                <div className="media align-items-center">
                                    <img alt="" src={`/assets/img/${project.folder}.png`}
                                         className="avatar avatar-lg avatar-square rounded"/>
                                    <div className="media-body">
                                        <h1 className="mb-0">{project.title}
                                            &nbsp;
                                            <button className="btn btn-sm btn-outline-primary" style={ {padding: "0.11rem 0.15rem", fontSize: "0.875rem", lineHeight: "1rem"}}
                                                    onClick={() => openModal('EditProjectModal', {projectId: project.id})}
                                            >
                                                <FontAwesomeIcon icon={faPencilAlt} />
                                            </button>
                                        </h1>
                                        <h2 className="lead mb-2">
                                            <Link to={`/companies/${project.company.id}`}>{project.company.name}</Link>
                                        </h2>

                                        <div className="d-flex align-items-center">
                                            <span className="badge badge-info mr-3">{project.folder}</span>
                                            <ul className="list-inline text-small d-inline-block">
                                                <li className="list-inline-item">
                                                    {project.totalCompleted.toFixed(1)} / {project.total.toFixed(1)} days
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-auto">
                                <PieChart width={125} height={125}>
                                    <Pie data={this.calcData(project)} innerRadius={40} outerRadius={60}
                                         fill="#82ca9d" dataKey={12}/>
                                    <text x={65} y={65} textAnchor="middle" dominantBaseline="middle">
                                        {this.timeLeft(project)}d left
                                    </text>
                                    <Tooltip/>
                                </PieChart>
                            </div>
                        </div>
                    </div>
                </section>

                {/*<ProjectStatusBar/>*/}

                <section className="flush-with-above space-0">
                    <div className="bg-white">
                        <div className="container">
                            <div className="row">
                                <div className="col">
                                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                                        <li className="nav-item">
                                            <a className="nav-link" id="overview-tab" data-toggle="tab"
                                               href="#general"
                                               onClick={() => {
                                                   this.props.history.push(`/Projects/${project.id}`)
                                               }}
                                               role="tab" aria-controls="general"
                                               aria-selected="true">Project Details</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" id="billing-tab"
                                               a href="#billing"
                                               onClick={() => {
                                                   this.props.history.push(`/ProjectsQuoteDetails/${project.id}`)
                                               }}>Quote
                                                Details</a>
                                        </li>

                                        <li className="nav-item">
                                            <a className="nav-link active" id="billing-tab" data-toggle="tab"
                                               href="#fulldetails"
                                               onClick={() => {
                                                   this.props.history.push(`/ProjectsFullDetails/${project.id}`)
                                               }}
                                               role="tab" aria-controls="billing" aria-selected="false">Full Project
                                                Details</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="space-sm">

                    <div className="container">
                        <div className="row justify-content-between">
                            <div className="col-12 col-md-12">
                                <DeliverableFullList Deliverables={project.deliverables} currentFilters={this.props.currentFilters}/>
                            </div>
                        </div>
                    </div>

                    <div className="container">
                        <div className="row justify-content-between">
                            <div className="col-12 col-md-12">
                                <button className="btn btn-primary" type="button"
                                        data-target="#deliverable-add" data-toggle="collapse"
                                        aria-expanded="false" aria-controls="deliverable-add"
                                        onClick={ () => openModal('NewDeliverableModal', {projectId: project.id}) }
                                >
                                    Append a new Deliverable
                                </button>
                            </div>
                        </div>
                    </div>

                </section>

            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    currentFilters: state.filters
});

export default connect(mapStateToProps, actions)(ProjectFullDetail);
