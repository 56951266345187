import React from 'react';
import { connect } from 'react-redux';
import { closeModal } from './modalActions'
import Modal from 'react-modal';
import ProjectEditComponent from "../Project/ProjectEditComponent";

const customStyles = {
    content : {
        top                   : '50%',
        left                  : '50%',
        right                 : 'auto',
        bottom                : 'auto',
        marginRight           : '-50%',
        transform             : 'translate(-50%, -50%)'
    }
};

const actions = {
    closeModal
}

class EditProjectModal extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            modalIsOpen: true
        };

        this.openModal = this.openModal.bind(this);
        this.afterOpenModal = this.afterOpenModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }

    componentWillMount() {
        Modal.setAppElement('body');
    }

    openModal() {
        this.setState({modalIsOpen: true});
    }

    afterOpenModal() {
        this.subtitle.style.color = '#f00';
    }

    closeModal() {
        this.props.closeModal()
    }

    render() {
        console.log("in here a.")
        return (
            <div>
                <Modal
                    isOpen={this.state.modalIsOpen}
                    onAfterOpen={this.afterOpenModal}
                    onRequestClose={this.closeModal}
                    style={customStyles}
                    contentLabel="Example Modal"
                >
                    <h2 ref={subtitle => this.subtitle = subtitle}>Edit Project Modal</h2>
                    <ProjectEditComponent projectId={'Project.id'} onCancel={this.closeModal} />
                </Modal>
            </div>
        );
    }

};

export default connect(null,actions)(EditProjectModal);