import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import {App} from './App';
import store from './store';
import ReduxToastr from "react-redux-toastr";

ReactDOM.render(
    <Provider store={store}>
        <div>
            <ReduxToastr
                position='bottom-right'
                transitionIn='fadeIn'
                transitionOut='fadeOut'
                preventDuplicates
            />
            <App/>
        </div>
    </Provider>, document.getElementById('root'));
