import React, { Component } from 'react';
import { loadProjects } from './requests';
import {
    Jumbotron, Breadcrumb, BreadcrumbItem
} from 'reactstrap';

export class AboutPage extends Component {

  constructor(props) {
    super(props);
    this.state = {Projects: []};
  }

  async componentDidMount() {
    const Projects = await loadProjects();
    this.setState({Projects});
  }

  render() {
    return (
      <div>

          <br/>

          <Breadcrumb>
              <BreadcrumbItem><a href="/">Home</a></BreadcrumbItem>
              <BreadcrumbItem active>About</BreadcrumbItem>
          </Breadcrumb>

          <Jumbotron>
              <h1 className="display-3">About</h1>
              <p className="lead">We offer custom bespoke software development using modern software languages and tools, including React, Javascript and Node.js</p>
              <hr className="my-2" />
              <p>New modern software development including agile mythologies, automated testing and build systems, when required.</p>
          </Jumbotron>

          <br/>
          <br/>

      </div>
    );
  }
}

