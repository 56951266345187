import React, { Fragment } from 'react';
import {toastr} from "react-redux-toastr";

class FooterSection extends React.Component
{

    showMessage(e) {
        e.preventDefault();
        toastr.warning("Feature currently not available");
    }

    render ()
    {
        return (
            <Fragment>
                <footer className="bg-gray text-light footer-long">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-md-6">
                                <img alt="" src="/companyimages/HJRb-DOuG.png" className="mb-4"/>
                                <p className="text-muted">
                                    &copy; 2022 Newtime Systems | 31-03-22-17-00
                                </p>
                            </div>
                            <div className="col-12 col-md-6">
                                <span className="h5">We stand on the shoulders of those before us.</span>
                                <div className="row no-gutters">
                                    <div className="col-6 col-lg-4">
                                        <h6>Company</h6>
                                        <ul className="list-unstyled">
                                            <li>
                                                <a href="/" onClick={ this.showMessage.bind(this) }>About company</a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="col-6 col-lg-4">
                                        <h6>Contact</h6>
                                        <ul className="list-unstyled">
                                            <li>
                                                <a href="/" onClick={ this.showMessage.bind(this) }>Contact</a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="col-6 col-lg-4">
                                        <h6>Site By:</h6>
                                        <ul className="list-unstyled">
                                            <li>
                                                <a href="https://www.jonathanleahy.co.uk/" >www.jonathanleahy.co.uk</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </Fragment>
        )
    }
}


export default FooterSection;
