import React, { Component } from 'react';
import { loadProjects } from './requests';
import FooterSection from "./FooterSection";

export class MarkdownTest2 extends Component {

  constructor(props) {
    super(props);
    this.state = {Projects: []};
  }

  async componentDidMount() {
    const Projects = await loadProjects();
    this.setState({Projects});
  }

  render() {
    return (
      <div>
          <div className="main-container">

              <section className="bg-gradient text-light p-0">
                  <div className="container">
                      <div className="row justify-content-between align-items-center">
                          <div className="col-12 col-md-7">
                              <div className="space-lg pb-0">
                                  <h1 className="display-4 no-wrap">
                                      The Masterbase
                                  </h1>
                                  <span className="lead">
                                    How does TWT keep track of their phones, sales and opportunities? The mastbase is THE centeral repostery for TWT. Here are the details for the manual Mastbase information
                                </span>
                              </div>
                          </div>
                      </div>
                  </div>
                  <svg className="decorative-divider" preserveAspectRatio="none" viewBox="0 0 100 100">
                      <polygon fill="#F8F9FB" points="0 100 100 100 100 0"></polygon>
                  </svg>
              </section>

              <section>
                  <div className="container">


                      <ul className="feature-list feature-list-lg">
                          <li className="row justify-content-around align-items-center">
                              <div className="col-12 col-md-6 col-lg-5">
                                  <img alt="" src="docimages/mb-excel.png"
                                       className="img-fluid rounded"
                                       data-action="zoom" />
                              </div>
                              <div className="col-12 col-md-6 col-lg-5">
                                  <h5>Masterbase - Excel File</h5>
                                  <p>
                                      The Mastbase is TWT's central respotary of data and information. The reposetry is made up from a combination of sources.
                                  </p>
                                  <a href="/">More about Phasers &rsaquo;</a>
                              </div>
                          </li>
                      </ul>
                  </div>
              </section>

              <section>
                  <div className="container">

                      <div className="row justify-content-center text-center section-intro">
                          <div className="col-12 col-md-9 col-lg-8">
                              <span className="title-decorative">Excel Processing</span>
                              <h2 className="display-4">The Masterbase Process</h2>
                              <span className="lead">How does TWT keep track of their phones, sales and opportunities?</span>

                          </div>
                      </div>

                      <ul className="feature-list feature-list-lg">
                          <li className="row justify-content-around align-items-center">
                              <div className="col-12 col-md-6 col-lg-5">
                                  <img alt="" src="docimages/masterbase01.png"
                                       className="img-fluid rounded"/>
                              </div>
                              <div className="col-12 col-md-6 col-lg-5">
                                  <h5>Masterbase - Central Storage</h5>
                              <p>
                                  As information comes from various places within TWT, a source of truth is the masterbase. This is a single Excel file with data collated from various sources.
                              </p>
                                  <a href="/">More about Phasers &rsaquo;</a>
                              </div>
                          </li>
                          <li className="row justify-content-around align-items-center">
                              <div className="col-12 col-md-6 col-lg-5 order-lg-2">
                                  <img alt="" src="assets/img/photo-team-desk.jpg" className="img-fluid rounded"/>
                              </div>
                              <div className="col-12 col-md-6 col-lg-5 order-lg-1">
                                  <h5>Keeping the Masterbase up to date</h5>
                                  <p>
                                      A role of Katys, is to keep the Masterbase up to date. Information will be pulled from The MAD system, the Vodafone Portal, and other places
                                  </p>
                                  <a href="/">More about Team Collaboration &rsaquo;</a>
                              </div>
                          </li>
                          <li className="row justify-content-around align-items-center">
                              <div className="col-12 col-md-6 col-lg-5">
                                  <img alt="" src="assets/img/photo-woman-laptop.jpg"
                                       className="img-fluid rounded"/>
                              </div>
                              <div className="col-12 col-md-6 col-lg-5">
                                  <h5>Keeping the team up to date</h5>
                                  <p>
                                      The up to date Masterbase information is provided to the sales people, information is also extracted from this file for summary information. The Masterbase can be used to create sales look up infromation where necessary.
                                  </p>
                                  <a href="/">More about Phasers &rsaquo;</a>
                              </div>
                          </li>
                      </ul>
                  </div>
              </section>

              <section>
                  <div className="container">

                      <div className="row justify-content-center text-center section-intro">
                          <div className="col-12 col-md-9 col-lg-8">
                              <span className="title-decorative">Excel Masterbase</span>
                              <h2 className="display-4">The Complete Process</h2>
                              <span className="lead">How does TWT keep track of their phones, sales and opportunities?</span>

                          </div>
                      </div>

                      <ul className="feature-list feature-list-lg">
                          <li className="row justify-content-around align-items-center">
                              <div className="col-12 col-md-6 col-lg-5">
                                  <img alt="" src="docimages/masterbase01.png"
                                       className="img-fluid rounded"/>
                              </div>
                              <div className="col-12 col-md-6 col-lg-5">
                                  <h5>Masterbase - Central Storage</h5>
                                  <p>
                                      As information comes from various places within TWT, a source of truth is the masterbase. This is a single Excel file with data collated from various sources.
                                  </p>
                                  <a href="/">More about Phasers &rsaquo;</a>
                              </div>
                          </li>
                      </ul>
                  </div>
              </section>

              <FooterSection/>

          </div>


      </div>
    );
  }
}

