import React, {Component } from 'react';
import {ProjectList} from './ProjectList';
import {loadProjects} from './requests';

export class ProjectBoard extends Component {

    constructor(props) {
        super(props);
        this.state = {projects: []};
    }

    async componentDidMount() {
        const projects = await loadProjects();
        this.setState({projects});
    }

    render() {
        const {projects} = this.state;

        return (
            <div>
                <div className="main-container">
                    <nav aria-label="breadcrumb" className="bg-primary text-white">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><a href="/dashboard">Home</a>
                                        </li>
                                        <li className="breadcrumb-item active" aria-current="page">Projects</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </nav>

                    <section>
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col">
                                    <h1 className="h2 mb-2">My Projects: Project Board</h1>
                                    <span>Useful for displaying an easily digestable collection of projects</span>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="flush-with-above">
                        <div className="container">
                            <div className="row">
                                <div className="col">
                                    <div className="d-flex justify-content-between align-items-center mb-4">
                                        <div>
                                            <span
                                                className="text-muted text-small">Results 1 - {projects.length} of {projects.length}</span>
                                        </div>
                                        <form className="d-flex align-items-center">
                                            <span className="mr-2 text-muted text-small text-nowrap">Sort by:</span>
                                            <select className="custom-select">
                                                <option value="alpha">Alphabetical</option>
                                                <option value="old-new" selected>Newest</option>
                                                <option value="new-old">Oldest</option>
                                                <option value="status">Status</option>
                                            </select>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <div className="table-responsive">
                                        <table className="table table-borderless align-items-center">
                                            <thead>
                                            <tr>
                                                <th scope="col">Name</th>
                                                <th scope="col">Progress</th>
                                                <th scope="col"></th>
                                                <th scope="col">Status</th>
                                                <th scope="col"></th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                                <ProjectList Projects={projects} filterfolder="inbox"/>
                                                <br/>

                                                <ProjectList Projects={projects} filterfolder="draft"/>
                                                <br/>

                                                <ProjectList Projects={projects} filterfolder="pending"/>
                                                <br/>

                                                <ProjectList Projects={projects} filterfolder=""/>
                                                <br/>

                                                <ProjectList Projects={projects} filterfolder="archive"/>
                                                <br/>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <br/>
                    <br/>

                </div>
            </div>
        );
    }
}
