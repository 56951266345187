import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';

export class CompanyList extends Component {
  render() {
    const {companies} = this.props;
    return (
<div>
        {/*<table className="table" style={{width: '100%'}}>*/}
          {/*<thead>*/}
          {/*<tr>*/}
              {/*<td style={{width: '90%'}}>Project</td>*/}
              {/*<td style={{width: '10%'}}>Status</td>*/}
          {/*</tr>*/}
          {/*</thead>*/}
          {/*<tbody>*/}
          {companies.map(this.renderCompany.bind(this))}
        {/*//   </tbody>*/}
        {/*// </table>*/}
        </div>
    );
  }

  renderCompany(company) {
    // const name = company.name;
    return (
        <Fragment>
            <div className="media" key={company.id}>
                <img alt="" src={`profileimages/${company.id}.png`} className="avatar"/>
                <div className="media-body">
                    <div>
                        <span className="text-muted text-small">{company.contact}</span>
                        <h5 className="h6 mb-1">
                            <Link to={`/companies/${company.id}`}>{company.name}</Link>
                            &nbsp;<span
                                className="badge badge-indicator badge-success">&nbsp;</span>
                        </h5>
                        <ul className="list-inline text-small text-muted">
                            <li className="list-inline-item"><i
                                className="icon-code mr-1"></i>Development
                            </li>
                            <li className="list-inline-item"><i
                                className="icon-message mr-1"></i>14
                            </li>
                            <li className="list-inline-item">
                                <small>Updated: 1 hour ago</small>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <p><br/></p>
        </Fragment>
    );
  }
}
