import React from 'react'
import {connect} from 'react-redux';
import {Formik} from "formik";
import {loadProject} from "../../requests";
import {saveProject} from "../../actions";
import {Form, Radio, Input, Textarea} from 'react-formik-ui'

class ProjectEditComponent extends React.Component {


    /* This is the deliverable so task needs updating to deliverable */
    constructor(props) {
        super(props);
        this.state = {
            projectId: this.props.currentModals.modalProps.projectId,
            project: []
        };
    }

    async componentDidMount() {
        const aProject = await loadProject(this.state.projectId);
        this.setState({project: aProject});
        console.log("did mount" + JSON.stringify(aProject) )
    }

    onDelete() {
        // this.props.dispatch(
        //     deleteDeliverable(
        //         this.state.deliverableId,
        //     ));
    }

    render() {

        if (this.state.project.length === 0) {
            return null
        }

        return (
            <div style={{width: '600px'}}>

                <Formik
                    initialValues={{
                        companyId: this.state.project.company.id,
                        devCompanyId: this.state.project.devCompanyId,
                        title: this.state.project.title,
                        folder: this.state.project.folder,
                        description: this.state.project.description,
                        percompleted: this.state.project.percompleted,
                        radioExample6: '2'
                    }}

                    onSubmit={values => {
                        console.log("-----", JSON.stringify(this.state))
                        this.props.dispatch(
                            saveProject(
                                this.state.projectId,
                                this.state.project.company.id,
                                this.state.project.devCompanyId,
                                values.title,
                                values.folder,
                                values.description,
                                this.state.percompleted
                            )
                        )
                        ;
                    }}

                    // validate={ values => {
                    //     let errors= {}
                    //     if (!values.title) {
                    //         errors.title = "please enter a name"
                    //     }
                    //     if (!values.duration) {
                    //         errors.duration= "please enter a duration"
                    //     }
                    //     return errors
                    // }}

                    render={() => (
                        <Form mode="structured">
                            <fieldset>

                                {/*<div className="form-group">*/}
                                {/*    <label htmlFor="exampleInputEmail1">Project Title</label>*/}
                                {/*    <input*/}
                                {/*        className="form-control"*/}
                                {/*        value=""*/}
                                {/*        type="text"*/}
                                {/*        name="title"*/}
                                {/*        placeholder="Enter your deliverable title"*/}
                                {/*    />*/}
                                {/*    <small id="emailHelp" className="form-text text-muted">A Deliverable title*/}
                                {/*    </small>*/}
                                {/*</div>*/}

                                {/*<div className="form-group">*/}
                                {/*    <label htmlFor="exampleInputEmail1">Description</label>*/}
                                {/*    <textarea*/}
                                {/*        className="form-control textarea"*/}
                                {/*        value={values.description}*/}
                                {/*        name="description"*/}
                                {/*        placeholder="Enter your description"*/}
                                {/*        onBlur={handleBlur}*/}
                                {/*    />*/}
                                {/*    <small id="emailHelp" className="form-text text-muted">A nice description*/}
                                {/*    </small>*/}
                                {/*</div>*/}

                                {/*FOLD:{values.folder}:ER*/}

                                <div className="form-group">
                                    <label htmlFor="exampleInputEmail1">Project Title</label>
                                    <Input
                                        className="form-control"
                                        name='title'
                                        placeholder="Enter your deliverable title"
                                        hint='A Deliverable title'
                                    />
                                </div>

                                <div className="form-group">
                                    <Textarea
                                        name='description'
                                        label='Description'
                                        className="form-control"
                                    />
                                </div>

                                <Radio
                                    name="folder"
                                    label="Folder"
                                    options={[
                                        {value: 'pending', label: 'Pending'},
                                        {value: 'inbox', label: 'Inbox'},
                                        {value: 'archive', label: 'Archive'}
                                    ]}
                                />

                                {/*<div className="form-group">*/}
                                {/*    <label htmlFor="exampleInputEmail1">Folder</label>*/}
                                {/*    <textarea*/}
                                {/*        className="form-control textarea"*/}
                                {/*        onChange={handleChange}*/}
                                {/*        value={values.folder}*/}
                                {/*        name="folder"*/}
                                {/*        placeholder="Enter folder"*/}
                                {/*        onBlur={handleBlur}*/}
                                {/*    />*/}
                                {/*    <small id="emailHelp" className="form-text text-muted">The Folder*/}
                                {/*    </small>*/}
                                {/*</div>*/}

                                {/*<div className="form-group">*/}
                                {/*    <label htmlFor="exampleInputEmail1">Project Id</label>*/}
                                {/*    <input*/}
                                {/*        className="form-control"*/}
                                {/*        onChange={handleChange}*/}
                                {/*        value={values.projectId}*/}
                                {/*        type="text"*/}
                                {/*        name="projectId"*/}
                                {/*        placeholder="Enter your Project Id"*/}
                                {/*        onBlur={handleBlur}*/}
                                {/*    />*/}
                                {/*    <small id="emailHelp" className="form-text text-muted">Don't mess with</small>*/}
                                {/*</div>*/}

                                <div className="row">
                                    <div className="col-sm-6">
                                        <button type="submit" className="btn btn-primary pull-right">Save</button>
                                        &nbsp;
                                        <button onClick={() => {
                                            this.props.onCancel()
                                        }} className="btn btn-secondary pull-right">Cancel
                                        </button>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="text-right">
                                            <button type="button" className="btn btn-danger" onClick={() => {
                                                this.onDelete()
                                            }}>Delete
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                {/*{*/}
                                {/*    errors.title &&*/}
                                {/*    <div style={{color: 'red'}}>*/}
                                {/*        {errors.title}*/}
                                {/*    </div>*/}
                                {/*}*/}
                            </fieldset>
                        </Form>
                    )

                    }
                >
                </Formik>

            </div>
        )

    };

}

const mapStateToProps = (state) => ({
    currentModals: state.modals
});


export default connect(mapStateToProps, null)(ProjectEditComponent);