import React from 'react'
import { connect } from 'react-redux';

import TaskNewForm from "./TaskNewForm";
import {newTask} from "../../../actions";

class TaskNewComponent extends React.Component {

    submit = values => {
        console.log("-+sssssssss-" + JSON.stringify(values));
        this.props.dispatch( newTask(values.title, this.props.currentModals.modalProps.deliverableId, 0.6) );
    };

    render() {
        console.log("new" + this.props.currentModals.modalProps.deliverableId)
        return <TaskNewForm deliverableId={this.props.currentModals.modalProps.deliverableId} title={"Output Full PDF details not working 1"} onSubmit={this.submit}/>
    };

}

const mapStateToProps = (state) => ({
    currentModals: state.modals
});


export default connect(mapStateToProps, null)(TaskNewComponent);