import React, { Component } from 'react';
import { CompanyList } from './CompanyList';
import { loadCompanies } from './requests';

export class CompanyBoard extends Component {

  constructor(props) {
    super(props);
    this.state = {companies: []};
  }

  async componentDidMount() {
    const companies = await loadCompanies();
    this.setState({companies});
  }

  render() {
    const {companies} = this.state;
  if (!companies) {
      return null;
  }

    return (

      <div>
          <div className="main-container">
              <nav aria-label="breadcrumb" className="bg-primary text-white">
                  <div className="container">
                      <div className="row justify-content-center">
                          <div className="col">
                              <ol className="breadcrumb">
                                  <li className="breadcrumb-item"><a href="/">Home</a>
                                  </li>
                                 <li className="breadcrumb-item active" aria-current="page">Companies</li>
                              </ol>
                          </div>
                      </div>
                  </div>
              </nav>
              <section className="space-sm">
                  <div className="container">
                      <div className="row">
                          <div className="col-12 col-md-9 mb-1 order-md-2">
                              <div className="card card-sm">
                                  <div
                                      className="card-header bg-secondary d-flex justify-content-between align-items-center">
                                      <div>
                                          <h6>Companies</h6>
                                      </div>
                                      <form className="d-flex align-items-center">
                                          <span className="mr-2 text-muted text-small text-nowrap">Sort by:</span>
                                          <select className="custom-select">
                                              <option value="old-new" selected>Newest</option>
                                              <option value="new-old">Popular</option>
                                              <option value="most">Most Comments</option>
                                          </select>
                                      </form>
                                  </div>
                                  <div className="list-group list-group-flush">

                                      <a className="list-group-item list-group-item-action" href="/">
                                          <CompanyList companies={companies} />
                                      </a>

                                      {/*<div className="card-header bg-secondary">*/}
                                          {/*<h6>Recently Archived</h6>*/}
                                      {/*</div>*/}

                                      {/*<a className="list-group-item list-group-item-action disabled" href="/">*/}
                                          {/*<div className="media">*/}
                                              {/*<img alt="" src="assets/img/avatar-female-3.jpg" className="avatar"/>*/}
                                              {/*<div className="media-body">*/}
                                                  {/*<div>*/}
                                                      {/*<span className="text-muted text-small">Penny Tran</span>*/}
                                                      {/*<h5 className="h6 mb-1">Name suggestions for the new running group*/}
                                                          {/*<span*/}
                                                              {/*className="badge badge-indicator badge-secondary">&nbsp;</span>*/}
                                                      {/*</h5>*/}
                                                      {/*<ul className="list-inline text-small text-muted">*/}
                                                          {/*<li className="list-inline-item"><i*/}
                                                              {/*className="icon-shield mr-1"></i>Security*/}
                                                          {/*</li>*/}
                                                          {/*<li className="list-inline-item"><i*/}
                                                              {/*className="icon-message mr-1"></i>8*/}
                                                          {/*</li>*/}
                                                          {/*<li className="list-inline-item">*/}
                                                              {/*<small>Updated: 5 days ago</small>*/}
                                                          {/*</li>*/}
                                                      {/*</ul>*/}
                                                  {/*</div>*/}
                                              {/*</div>*/}
                                          {/*</div>*/}
                                      {/*</a>*/}

                                      {/*<a className="list-group-item list-group-item-action disabled" href="/">*/}
                                          {/*<div className="media">*/}
                                              {/*<img alt="" src="assets/img/avatar-male-1.jpg" className="avatar"/>*/}
                                              {/*<div className="media-body">*/}
                                                  {/*<div>*/}
                                                      {/*<span className="text-muted text-small">Michael Duponte</span>*/}
                                                      {/*<h5 className="h6 mb-1">Meeting minutes reminder*/}
                                                          {/*<span*/}
                                                              {/*className="badge badge-indicator badge-secondary">&nbsp;</span>*/}
                                                      {/*</h5>*/}
                                                      {/*<ul className="list-inline text-small text-muted">*/}
                                                          {/*<li className="list-inline-item"><i*/}
                                                              {/*className="icon-folder mr-1"></i>Storage*/}
                                                          {/*</li>*/}
                                                          {/*<li className="list-inline-item"><i*/}
                                                              {/*className="icon-message mr-1"></i>1*/}
                                                          {/*</li>*/}
                                                          {/*<li className="list-inline-item">*/}
                                                              {/*<small>Updated: A week ago</small>*/}
                                                          {/*</li>*/}
                                                      {/*</ul>*/}
                                                  {/*</div>*/}
                                              {/*</div>*/}
                                          {/*</div>*/}
                                      {/*</a>*/}

                                  </div>
                              </div>
                              <div className="d-flex justify-content-center">
                                  <nav aria-label="Page navigation example">
                                      <ul className="pagination">
                                          <li className="page-item disabled">
                                              <a className="page-link" href="/" aria-label="Previous">
                                                <span aria-hidden="true"><i className="icon-chevron-left"></i>
                                                </span>
                                                  <span className="sr-only">Previous</span>
                                              </a>
                                          </li>
                                          <li className="page-item active"><a className="page-link" href="/">1</a>
                                          </li>
                                          <li className="page-item"><a className="page-link" href="/">2</a>
                                          </li>
                                          <li className="page-item"><a className="page-link" href="/">3</a>
                                          </li>
                                          <li className="page-item">
                                              <a className="page-link" href="/" aria-label="Next">
                                                <span aria-hidden="true"><i className="icon-chevron-right"></i>
                                                </span>
                                                  <span className="sr-only">Next</span>
                                              </a>
                                          </li>
                                      </ul>
                                  </nav>
                              </div>
                          </div>
                          <div className="col-12 col-md-3 mb-1 order-md-1">
                              <div className="card">
                                  <div className="card-body">
                                      <form className="mb-4">
                                          <input className="form-control" placeholder="Search" type="search"
                                                 name="search-conversations"/>
                                      </form>
                                      <nav className="nav flex-md-column">
                                          <a href="/" className="nav-link active">All</a>
                                          <a href="/" className="nav-link">
                                              <span className="badge badge-indicator badge-success mr-1">&nbsp;</span>On
                                              track</a>
                                          <a href="/" className="nav-link">
                                              <span className="badge badge-indicator badge-warning mr-1">&nbsp;</span>Falling
                                              behind</a>
                                          <a href="/" className="nav-link">
                                              <span className="badge badge-indicator badge-danger mr-1">&nbsp;</span>Needs
                                              a call</a>
                                          <a href="/" className="nav-link">
                                              <span className="badge badge-indicator badge-secondary mr-1">&nbsp;</span>Archived</a>
                                      </nav>
                                  </div>
                              </div>
                              <div className="card card-sm">

                                  <div className="card-header">
                                      <span className="h6">Categories</span>
                                  </div>
                                  <div className="list-group list-group-flush">

                                      <a className="list-group-item d-flex justify-content-between" href="/">
                                          <div>
                                              <i className="icon-area-graph mr-1"></i>
                                              <span>Analytics</span>
                                          </div>
                                          <div>
                                              <i className="icon-chevron-right"></i>
                                          </div>
                                      </a>

                                      <a className="list-group-item d-flex justify-content-between" href="/">
                                          <div>
                                              <i className="icon-power-plug mr-1"></i>
                                              <span>API Integration</span>
                                          </div>
                                          <div>
                                              <i className="icon-chevron-right"></i>
                                          </div>
                                      </a>

                                      <a className="list-group-item d-flex justify-content-between" href="/">
                                          <div>
                                              <i className="icon-credit-card mr-1"></i>
                                              <span>Billing</span>
                                          </div>
                                          <div>
                                              <i className="icon-chevron-right"></i>
                                          </div>
                                      </a>

                                      <a className="list-group-item d-flex justify-content-between" href="/">
                                          <div>
                                              <i className="icon-code mr-1"></i>
                                              <span>Development</span>
                                          </div>
                                          <div>
                                              <i className="icon-chevron-right"></i>
                                          </div>
                                      </a>

                                      <a className="list-group-item d-flex justify-content-between" href="/">
                                          <div>
                                              <i className="icon-shield mr-1"></i>
                                              <span>Security</span>
                                          </div>
                                          <div>
                                              <i className="icon-chevron-right"></i>
                                          </div>
                                      </a>

                                      <a className="list-group-item d-flex justify-content-between" href="/">
                                          <div>
                                              <i className="icon-folder mr-1"></i>
                                              <span>Storage</span>
                                          </div>
                                          <div>
                                              <i className="icon-chevron-right"></i>
                                          </div>
                                      </a>

                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </section>
              <footer className="footer-short">
                  <div className="container">
                          <nav className="row justify-content-between align-items-center">
                              <div className="col-auto">
                                  <ul className="list-inline">
                                      <li className="list-inline-item">
                                          <a href="/">
                                              <img alt="" src="assets/img/logo-gray.svg"/>
                                          </a>
                                      </li>
                                      <li className="list-inline-item">
                                          <a href="/">Overview</a>
                                      </li>
                                      <li className="list-inline-item">
                                          <a href="/">Documentation</a>
                                      </li>
                                      <li className="list-inline-item">
                                          <a href="/">Changelog</a>
                                      </li>
                                  </ul>
                              </div>
                              <div className="col-auto text-sm-right">
                                  <ul className="list-inline">
                                      <li className="list-inline-item">
                                          <a href="/"><i className="socicon-twitter"></i></a>
                                      </li>
                                      <li className="list-inline-item">
                                          <a href="/"><i className="socicon-facebook"></i></a>
                                      </li>
                                  </ul>
                              </div>
                          </nav>
                          <div className="row">
                              <div className="col">
                                  <small>&copy; 2018 Medium Rare All Rights Reserved</small>
                              </div>
                          </div>
                  </div>
              </footer>
          </div>

      </div>

    );
  }
}
