const initialState = {
    data: []
};

const project = (state = initialState, action) => {
    switch(action.type) {
        case 'RECEIVE_PROJECT':
            return {
                ...state,
                data: action.project
            };
        case 'DELETE_TASKS':
            return {};
        default:
            return state;
    }
};

export default project;