import React, { Component, Fragment } from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFilePdf} from "@fortawesome/free-solid-svg-icons";


export class QuoteSidebar extends Component {

  notdone(total, completed) {

      let outp = ""

      for (let i = 0, len = total; i < len; i++) {
          outp = outp + <FontAwesomeIcon icon={faFilePdf}/>
      }

      for (let i1 = 0, len = (total - completed); i1 < len; i1++) {
          outp = outp + "-"
      }

      return outp

  }

  render() {

    let completed =  new Array( parseInt(this.props.totalCompleted) )
    completed.fill('a', 0, 9999)

    let uncompleted = new Array( parseInt(this.props.total - this.props.totalCompleted))
    uncompleted.fill('a', 0, 9999)

    return (
        <Fragment>

            <div>
                <span className="h6">Day Rate:</span>
            </div>

            <div>
                <br/>
            </div>

            <div>
                <span className="h6">Min Days:</span>
            </div>

            <div>
                <br/>
            </div>

            <div>
                <span className="h6">Max Days:</span>
            </div>

        </Fragment>
    );
  }

  renderDeliverable() {
  }

}
