import React from 'react'
import { connect } from 'react-redux';

import DeliverableNewForm from "./DeliverableNewForm";
// import {newDeliverable} from "../../../actions";

class DeliverableNewComponent extends React.Component {
    // submit = values => {
    //     console.log(values);
    //     this.props.dispatch( newDeliverable(values.title, this.props.currentModals.modalProps.projectId) );
    // };
    render() {
        return <DeliverableNewForm projectId={this.props.currentModals.modalProps.projectId} />
    };
}

const mapStateToProps = (state) => ({
    currentModals: state.modals
});

export default connect(mapStateToProps, null)(DeliverableNewComponent);