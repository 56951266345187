const initialState = {
    data: [
        {hideCompleted: false}
    ]
};

const filters = (state = initialState, action) => {
    switch(action.type) {
        case 'UPDATE_SHOW_COMPLETED':
            console.log("cc")
            return {
                ...state,
                data: [ { hideCompleted: action.payload} ]
        };
        default:
            return state;
    }
};


export default filters;