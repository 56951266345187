import React, { Component, Fragment } from 'react';

export class ProjectStatusBar extends Component {

  render() {

    return (
      <Fragment>

          <section className="bg-white space-sm flush-with-above">
              <div className="container">
                  <div className="row mb-3">
                      <div className="col">
                          <div className="progress">

                              <div className="progress-bar bg-success opacity-50" role="progressbar"
                                   style={{width: '20%'}} aria-valuenow="15" aria-valuemin="0"
                                   aria-valuemax="100"></div>
                              <div className="progress-bar bg-success opacity-50" role="progressbar"
                                   style={{width: '20%'}} aria-valuenow="15" aria-valuemin="0"
                                   aria-valuemax="100"></div>
                              <div className="progress-bar bg-success opacity-50" role="progressbar"
                                   style={{width: '20%'}} aria-valuenow="15" aria-valuemin="0"
                                   aria-valuemax="100"></div>
                              <div className="progress-bar bg-success" role="progressbar" style={{width: '20%'}}
                                   aria-valuenow="30" aria-valuemin="0" aria-valuemax="100"></div>
                          </div>
                      </div>
                  </div>
                  <div className="row text-center">
                      <div className="col opacity-50">
                          <span>Draft</span>
                          {/*<br />*/}
                          {/*<small className="text-muted"><i className="icon-check mr-1"></i>Completed: --th*/}
                              {/*-----*/}
                          {/*</small>*/}
                      </div>
                      <div className="col opacity-50">
                          <span>Reviewed</span>
                          {/*<br />*/}
                          {/*<small className="text-muted"><i className="icon-check mr-1"></i>Due: -- ----*/}
                          {/*</small>*/}
                      </div>
                      <div className="col opacity-50">
                          <span>Prototype</span>
                          {/*<br />*/}
                          {/*<small className="text-muted"><i className="icon-check mr-1"></i>Due: -- ----*/}
                          {/*</small>*/}
                      </div>
                      <div className="col">
                          <span>Development</span>
                          {/*<br />*/}
                          {/*<small className="text-muted"><i className="icon-calendar mr-1"></i>Due: -- ----*/}
                          {/*</small>*/}
                      </div>
                      <div className="col">
                          <span>Archive</span>
                          {/*<br />*/}
                          {/*<small className="text-muted"><i className="icon-calendar mr-1"></i>Due: -- ----*/}
                          {/*</small>*/}
                      </div>
                  </div>
              </div>
          </section>

      </Fragment>
    );

  }

}
