import React, { Component, Fragment } from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faCircle } from '@fortawesome/free-regular-svg-icons';
import {faCheckCircle, faFilePdf} from "@fortawesome/free-solid-svg-icons";


export class CompletedSquares extends Component {

  notdone(total, completed) {

      let outp = ""

      for (let i = 0, len = total; i < len; i++) {
          outp = outp + <FontAwesomeIcon icon={faFilePdf}/>
      }

      for (let i1 = 0, len = (total - completed); i1 < len; i1++) {
          outp = outp + "-"
      }

      return outp

  }

  render() {

    let completed =  new Array( parseInt(this.props.totalCompleted) )
    completed.fill('a', 0, 9999)

    let uncompleted = new Array( parseInt(this.props.total - this.props.totalCompleted))
    uncompleted.fill('a', 0, 9999)

    return (
        <Fragment>

            { completed.map( function () {
                return (
                    <Fragment>
                        <FontAwesomeIcon icon={faCheckCircle}/>&nbsp;
                    </Fragment>
                )
            } )}

            { uncompleted.map( function () {
                return (
                    <Fragment>
                        <FontAwesomeIcon icon={faCircle}/>&nbsp;
                    </Fragment>
                )
            } )}

        </Fragment>
    );
  }

  renderDeliverable() {
  }

}
