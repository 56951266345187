import React, {Component, Fragment} from 'react';
import _ from 'lodash';

export class ProjectList extends Component {

    constructor(props) {
        super(props)
        this.state = {Projects: []}
    }

    render() {
        const {Projects} = this.props;

        let filteredProjects = [];

        if (this.props.filterfolder !== undefined) {
            filteredProjects = Projects.filter(elem => {
                return elem.folder === this.props.filterfolder;
            })
        } else {
            filteredProjects = Projects
        }

        if (filteredProjects.length === 0) {
            return null;
        } else {
            return (
                <Fragment>
                    <tr>
                        <td><h3>{_.upperFirst(this.props.filterfolder)}</h3></td>
                    </tr>
                    {filteredProjects.map(this.renderProject.bind(this))}
                    <tr>
                        <td><br/></td>
                    </tr>
                </Fragment>
            );
        }

    }

    renderProject(Project) {
        const title = Project.title;

        return (
            <Fragment key={title}>
                <tr className="bg-white">
                    <th scope="row" className="w-50" colSpan="2">
                        <a className="media align-items-center" href={`/ProjectsFullDetails/${Project.id}`}>
                            <img alt="" src={`/assets/img/${Project.folder}.png`}
                                 className="avatar rounded mr-3"/>
                            <div className="media-body">
                                <span className="h6 mb-0">{title} </span>
                                <span className="text-muted">
                                    {Project.totalCompleted}d / {(Project.total).toFixed(1)}d days
                                </span>
                            </div>
                        </a>
                    </th>
                    <td className="w-25" colSpan="3">
                        <div className="progress progress-sm">
                            <div className="progress-bar" role="progressbar" aria-valuenow="95"
                                 aria-valuemin="0" aria-valuemax="100"
                                 style={{width: `${Project.perccompleted}%`}}/>
                        </div>
                    </td>
                    <td align="right">
                        {(Project.totalCompleted === Project.total) && (Project.total > 0) &&
                            <i className="text-green fas fa-flag-checkered fa-2x"/>
                        }
                    </td>
                    <td/>
                </tr>
                <tr className="table-divider"/>
            </Fragment>
        );

    }

}
