import React, { Component } from 'react';
import { loadProjects } from './requests';
import { Jumbotron, Button } from 'reactstrap';

export class TransparencyPage extends Component {

  constructor(props) {
    super(props);
    this.state = {Projects: []};
  }

  async componentDidMount() {
    const Projects = await loadProjects();
    this.setState({Projects});
  }

  render() {
    //const {Projects} = this.state;

    return (
      <div>

          <br/>

          <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                  <li className="breadcrumb-item"><a href="/">Home</a></li>
                  <li className="breadcrumb-item active" aria-current="page">Transparency</li>
              </ol>
          </nav>

          <Jumbotron>
              <h1 className="display-3">TransparencyPage</h1>
              <p className="lead">We offer custom bespoke software development using modern software languages and tools, including React, Javascript and Node.js</p>
              <hr className="my-2" />
              <p>New modern software development including agile mythologies, automated testing and build systems, when required.</p>
              <p className="lead">
                  <Button color="primary">Learn More</Button>
              </p>
          </Jumbotron>

          <br/>
          <br/>

      </div>
    );
  }
}

