import {
    createDeliverable,
    createTask,
    removeDeliverable,
    removeTask,
    updateDeliverable,
    updateProject,
    updateTask
} from "../requests";

import {toastr} from 'react-redux-toastr';
import {closeModal} from "../components/Modals/modalActions";

const RECIEVE_DELIVERABLES = 'RECIEVE_DELIVERABLES';
const recieveDeliverables = (deliverables) => ({
    type: RECIEVE_DELIVERABLES,
    deliverables
});

export const newDeliverable = (text, description, projectId) => {
        console.log("create deliverable" + projectId)
        return dispatch => {
            createDeliverable({
                title: text,
                description: description,
                projectId: projectId
            }).then(response => {
                    dispatch(recieveDeliverables());
                    toastr.success('Deliverable Added', text);
                    dispatch(closeModal());
                }
            )
                .finally(
                    window.location.reload()
                )
        }
    }
;

export const newTask = (text, description, deliverableId, time, importance) => {
        console.log("new task" + deliverableId)
        return dispatch => {
            createTask({
                title: text,
                description: description,
                deliverableId: deliverableId,
                time: time,
                importance: importance
            }).then(response => {
                    dispatch(recieveDeliverables());
                    toastr.success('Deliverable Added', text);
                    dispatch(closeModal());
                }
            )
                .finally(
                    window.location.reload()
                )
        }
    }
;

// const RECIEVE_TASKS = 'RECIEVE_TASKS';
const recieveTasks = (tasks) => ({
    type: RECIEVE_DELIVERABLES,
    tasks
});

export const saveTask = (id, deliverableId, text, description, complete, time, importance, customerRelated) => {

        return dispatch => {
            updateTask({
                id: id,
                deliverableId: deliverableId,
                title: text,
                complete: complete,
                description: description,
                time: time,
                importance: importance,
                customerRelated: customerRelated,
            }).then(response => {
                    dispatch(recieveTasks());
                    //toastr.success('Task Saved', text);
                    dispatch(closeModal());
                }
            )
                .finally(
                    window.location.reload()
                )
        }
    }
;

export const saveDeliverable = (id, projectId, title, description) => {
        return dispatch => {
            console.log("jj", id)
            updateDeliverable({
                id: id,
                projectId: projectId,
                title: title,
                description: description
            }).then(response => {
                    dispatch(recieveDeliverables());
                    toastr.success('Deliverable Saved', title);
                    dispatch(closeModal());
                }
            )
                .finally(
                    window.location.reload()
                )
        }
    }
;

export const saveProject = (id, companyId, devCompanyId, title, folder, description, percompleted) => {
        return dispatch => {
            console.log("jj", id)
            updateProject({
                id: id,
                companyId: companyId,
                devCompanyId: devCompanyId,
                title: title,
                folder: folder,
                description: description,
                percompleted: percompleted
            }).then(response => {
                    dispatch(recieveDeliverables());
                    toastr.success('Deliverable Saved', title);
                    dispatch(closeModal());
                }
            )
                .finally(
                    window.location.reload()
                )
        }
    }
;


export const deleteTask = (id) => {
        return dispatch => {
            console.log("aa", id)
            removeTask({
                id: id
            }).then(response => {
                    dispatch(recieveTasks());
                    toastr.success('Task Deleted');
                    dispatch(closeModal());
                }
            )
                .finally(
                    window.location.reload()
                )
        }
    }
;


export const deleteDeliverable = (id) => {

        return dispatch => {
            console.log("jj", id)
            removeDeliverable({
                id: id
            }).then(response => {
                    dispatch(recieveDeliverables());
                    toastr.success('Deliverable Deleted');
                    dispatch(closeModal());
                }
            )
                .finally(
                    window.location.reload()
                )
        }
    }
;
