import React, {Component} from 'react';

export class NavBar extends Component {
    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.state = {
            isOpen: false
        };
    }

    toggle() {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }

    render() {
        const {loggedIn, onLogout} = this.props;

        if (loggedIn) {
            return (
                <div className="nav-container">
                    <div className="bg-dark navbar-dark" data-sticky="top">
                        <div className="container">
                            <nav className="navbar navbar-expand-lg">
                                <a className="navbar-brand" href="/">
                                    <p>Newtime Systems</p>
                                </a>
                                <button className="navbar-toggler" type="button" data-toggle="collapse"
                                        data-target="#navbarNav"
                                        aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                                    <i className="icon-menu h4"></i>
                                </button>

                                <div className="collapse navbar-collapse justify-content-between" id="navbarNav">

                                    <ul className="navbar-nav">
                                        <li className="nav-item">
                                            <a href="/dashboard" className="nav-link">Dashboard</a>
                                        </li>
                                        <li className="nav-item">
                                            <a href="/projects" className="nav-link">All Projects</a>
                                        </li>
                                    </ul>

                                    <ul className="navbar-nav">
                                        <li className="nav-item">
                                            <a href="/" onClick={onLogout}><i className="fas fa-sign-out"
                                                                              aria-hidden="true"></i>&nbsp;Logout</a>
                                        </li>
                                    </ul>

                                </div>
                            </nav>
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <div className="nav-container">
                    <div className="bg-dark navbar-dark" data-sticky="top">
                        <div className="container">
                            <nav className="navbar navbar-expand-lg">
                                <a className="navbar-brand" href="/">
                                    <p>Newtime Systems</p>
                                </a>
                                <button className="navbar-toggler" type="button" data-toggle="collapse"
                                        data-target="#navbarNav"
                                        aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                                    <i className="icon-menu h4"></i>
                                </button>
                                <div className="collapse navbar-collapse justify-content-between" id="navbarNav">
                                    <ul className="navbar-nav">
                                    </ul>
                                    <ul className="navbar-nav">
                                        <li className="nav-item">
                                            <a href="/login"><i className="fas fa-sign-in"
                                                                aria-hidden="true"></i>&nbsp;Login</a>
                                        </li>
                                    </ul>
                                </div>
                            </nav>
                        </div>
                    </div>
                </div>
            );
        }

    }
}
