const initialState = {
    data: []
};

const deliverables = (state = initialState, action) => {
    switch(action.type) {
        case 'RECEIVE_DELIVERABLES':
            console.log("kk")
            return {
                ...state,
                data: action.deliverables
            };
        case 'DELETE_DELIVERABLE':
            return {};
        default:
            return state;
    }
};

export default deliverables;