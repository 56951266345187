import React, {Component, Fragment} from 'react';

export class HighlightText extends Component {

    constructor(props) {
        super(props);
        this.state = {isEditing: false};
    }

    onEdit() {
        this.setState(state => ({isEditing: !state.isEditing}));
    }

    render() {

        if (!this.state.isEditing) {
            let showCheck = this.props.showCheck;
            let showText = this.props.showText;

            if (typeof showCheck === "undefined") {
                if (typeof showText === "undefined") {
                    showCheck = true;
                    showText = true;
                }
            }

            if (this.props.completed) {
                return (
                    <span>
                {showCheck && (
                    <i className="fas fa-lg fa-check-square">&nbsp;&nbsp;</i>
                )}
                        {showText && (
                            <Fragment>
                    <span style={cardStyle}>
                        {this.props.children}
                    </span>
                                {/*&nbsp;&nbsp;*/}
                                {/*<button onClick={this.onEdit.bind(this)}><i className="far fa-edit"></i></button>*/}
                            </Fragment>
                        )}
            </span>
                )
            } else {
                return (
                    <span>
                {showCheck && (
                    <i className="fas fa-lg fa-square" style={unchecked}>&nbsp;&nbsp;</i>
                )}
                        {showText && (
                            <Fragment>
                    <span>
                        {this.props.children}
                    </span>
                                {/*&nbsp;&nbsp;*/}
                                {/*<button onClick={this.onEdit.bind(this)}><i className="far fa-edit"></i></button>*/}
                            </Fragment>
                        )}
            </span>
                )
            }
        } else {
            return (
                <span>
                    {/*<button onClick={this.onEdit.bind(this)}><i className="far fa-edit"></i></button>*/}
                </span>
            )
        }

    }

}

export const cardStyle = {
    backgroundColor: 'indigo',
    background: 'rgba(75, 0, 130, 0.1)'
}

export const unchecked = {
    opacity: 0.2
}

export default HighlightText;