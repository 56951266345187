import React, { Component } from 'react';
import { login } from './auth';

export class LoginForm extends Component {
  constructor(props) {
    super(props);
    this.state = {email: '', password: '', error: false};
  }

  handleChange(event) {
    const {name, value} = event.target;
    this.setState({[name]: value});
  }

  handleClick(event) {
    event.preventDefault();
    const {email, password} = this.state;
    login(email, password).then((ok) => {
      if (ok) {
        this.props.onLogin();
      } else {
        this.setState({error: true});
      }
    });
  }

  render() {
    const {email, error} = this.state;
    return (

    <div>
        <div className="nav-container">
        </div>
        <div className = "main-container" >
          <section className = "space-sm" >
              <div className = "container align-self-start" >
                  <div className = "row mb-5" >
                      <div className = "col text-center" >
                        <a href = "/" >
                            <i className="text-muted fas fa-5x fa-sign-in"></i>
                        </a>
                      </div>
                  </div>

                  <div className="row justify-content-center">
                    <div className="col-12 col-md-8 col-lg-7">
                        <div className="card card-lg text-center">
                            <div className="card-body">
                                <div className="mb-5">
                                    <h1 className="h2 mb-2">Welcome</h1>
                                    <span>Sign in to your account to continue</span>
                                </div>
                                <div className="row no-gutters justify-content-center">
                                    <form className="text-left col-lg-8">
                                        <div className="form-group">
                                            <label htmlFor="login-email">Email Address</label>
                                            <input className="form-control form-control-lg" type="email" name="email"
                                                   value={email}
                                                   placeholder="Email Address"
                                                   onChange={this.handleChange.bind(this)}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="login-password">Password</label>
                                            <input
                                                className="form-control form-control-lg"
                                                type="password"
                                                name="password"
                                                placeholder="Enter a password"
                                                onChange={this.handleChange.bind(this)}
                                            />
                                            {/*<small>Forgot password? <a href="#">Reset here</a>*/}
                                            {/*</small>*/}
                                        </div>
                                        {/*<div>*/}
                                            {/*<div className="custom-control custom-checkbox align-items-center">*/}
                                                {/*<input type="checkbox" className="custom-control-input" value="box-1" name="box-1"*/}
                                                 {/*checked id="check-remember" />*/}
                                                {/*<label className="custom-control-label text-small" htmlFor="check-remember">Remember*/}
                                                  {/*me next time</label>*/}
                                            {/*</div>*/}
                                        {/*</div>*/}
                                        <div className="text-center mt-3">
                                            <p className="help text-danger">{error && 'Invalid credentials'}</p>
                                            <br/>
                                            <br/>
                                            <button className="btn btn-lg btn-primary" onClick={this.handleClick.bind(this)}>Log in</button>
                                        </div>
                                    </form>

                                </div>
                            </div>
                        </div>
                        {/*<div className="text-center">*/}
                            {/*<span className="text-small">Don't have an account yet? <a href="#">Create one</a>*/}
                            {/*</span>*/}
                        {/*</div>*/}
                    </div>
                  </div>
              </div>
            </section>
         </div>
        </div>
    );
  }
}
