const initialState = {
    data: []
};

const tasks = (state = initialState, action) => {
    switch(action.type) {
        case 'RECEIVE_TASKS':
            return {
                ...state,
                data: action.tasks
            };
        case 'DELETE_TASKS':
            return {};
        default:
            return state;
    }
};

export default tasks;