import React, {Component, Fragment} from 'react';
import {COLOR_GREEN, COLOR_RED} from './consts/consts';
import TaskListProposal from "./TaskListProposal";
import {connect} from "react-redux";
import {openModal} from "./components/Modals/modalActions";

const actions = {openModal}

class DeliverableFullList extends Component {

    render() {
        const {Deliverables} = this.props;
        return (
            <Fragment>
                <div className='page-divide'></div>
                {Deliverables.map(this.renderDeliverable.bind(this))}
            </Fragment>
        );
    }

    renderDeliverable(Deliverable) {

        let calcCompleted = 0;
        let calcTodo = 0;

        let data02 = Deliverable.tasks.map((task) => {
            const tmpname = "Jell";
            const tmpvalue = task.time;
            let tmpfill = COLOR_RED;
            if (task.complete) {
                tmpfill = COLOR_GREEN;
                calcCompleted = calcCompleted + task.time;
            } else {
                calcTodo = calcTodo + task.time;
            }
            const ret = {name: tmpname, value: tmpvalue, fill: tmpfill};
            return ret;
        })

        if (data02.length > 5) {
            data02 = [
                {name: "Completed", value: calcCompleted, fill: COLOR_GREEN},
                {name: "Todo", value: calcTodo, fill: COLOR_RED},
            ];
        }

        //const { openModal } = this.props;

        return (
                <div className="row" key={Deliverable.id}>
                    <TaskListProposal Deliverable={Deliverable} currentFilters={this.props.currentFilters}/>
                </div>
        );
    }
}

export default connect(null, actions)(DeliverableFullList);
