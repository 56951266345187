// duplicated file in server and on newtimesystems.com

export default class jsonAddTotals {

    constructor(jsonIn) {
        this.data = jsonIn
    }

    // each deliverable will get two new fields:
    // total and totalCompleted

    // the project will get two new fields:
    // total and totalCompleted

    calcaDeliverableTotal(deliverable) {

        let deliverableTotal = 0;
        let deliverableCompleted = 0;

        deliverable.tasks.map(function(task, key) {
            deliverableTotal = deliverableTotal + task.time
            if (task.complete) {
                deliverableCompleted = deliverableCompleted + task.time
            }
            return "";
        })

        deliverable.total = deliverableTotal
        deliverable.totalcompleted = deliverableCompleted

    }

    calcDeliverableTotals() {
        let datas = JSON.parse(JSON.stringify(this.data))
        this.calcaDeliverableTotal(datas.deliverable);
        return datas;
    }

    calcProjectTotals() {

        let datas = JSON.parse(JSON.stringify(this.data))

        let projectTotal = 0
        let projectCompleted = 0

        datas.project.deliverables.map(function(deliverable, key) {

            // todo: to fix, this line crashes the system
            // the code underneath is duplicated and should be replaced with this
            // but it's not working
            // this.calcaDeliverableTotal(deliverable);

            let deliverableTotal = 0;
            let deliverableCompleted = 0;
            deliverable.tasks.map(function(task, key) {
                deliverableTotal = deliverableTotal + task.time
                if (task.complete) {
                    deliverableCompleted = deliverableCompleted + task.time
                }
                return null
            })

            deliverable.total = deliverableTotal
            deliverable.totalcompleted = deliverableCompleted

            projectTotal = projectTotal + deliverable.total
            projectCompleted = projectCompleted + deliverable.totalcompleted
            return null
        });

        datas.project.total = projectTotal
        datas.project.totalCompleted = projectCompleted
        datas.project.perccompleted = ( projectCompleted / projectTotal ) * 100;

        return datas
    }

    calcProjectsTotals() {
        let datas = JSON.parse(JSON.stringify(this.data))
        let projects = datas.company.projects.map(function(project, key) {
            let aproject = {};
            aproject.project = project;
            let jsondata = new jsonAddTotals(aproject)
            return jsondata.calcProjectTotals().project;
        });
        datas.company.projects = projects;
        return datas
    }

    calcProjectsOnlyTotals() {
        let datas = JSON.parse(JSON.stringify(this.data))
        let projects = datas.projects.map(function(project, key) {
            let aproject = {};
            aproject.project = project;
            let jsondata = new jsonAddTotals(aproject)
            return jsondata.calcProjectTotals().project;
        });
        datas.projects = projects;
        return datas
    }

}
