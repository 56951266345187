import {createStore, compose, combineReducers, applyMiddleware} from 'redux';
import rootReducer from './reducers';
import thunk from 'redux-thunk';
import {reducer as formReducer} from 'redux-form';
import { reducer as toastrReducer } from 'react-redux-toastr';
import modalReducer from '../src/components/Modals/modalReducer';
import filters from "../src/reducers/filters";

const combinedReducer = combineReducers({
    rootReducer,
    form: formReducer,
    toastr: toastrReducer,
    modals: modalReducer,
    filters
});

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(combinedReducer,
    composeEnhancer (applyMiddleware(thunk)));

export default store;