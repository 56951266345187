import React, {Component} from 'react';
import {loadProjects} from './requests';
import FooterSection from "./FooterSection";
import {toastr} from "react-redux-toastr";

export class HomePage2 extends Component {

    constructor(props) {
        super(props);
        this.state = {Projects: []};
    }

    async componentDidMount() {
        const Projects = await loadProjects();
        this.setState({Projects});
    }

    noEmail(e) {
        e.preventDefault();
        toastr.warning("Feature currently not available");
    }

    render() {

        return (
            <div>

                <div className="main-container">
                    <section className="space-lg bg-gradient overflow-hidden">
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-12 col-md-6 mb-5 mb-md-0 position-relative">
                                    <h1 className="display-4">Bespoke Software/Web Development</h1>
                                    {/*<p className="lead">*/}
                                    {/*<img alt="Image" src="/companyimages/HJRb-DOuG.png" />*/}
                                    {/*</p>*/}
                                    <p className="lead">
                                        We offer custom software development using modern software languages and tools:
                                        React, React-Native, Javascript and Node.js
                                        <br/><br/>
                                        Monitor progress of your project using our online management tools for increased
                                        visibility.
                                        <br/><br/>
                                    </p>
                                    {/*<a href="https://themes.getbootstrap.com/product/wingman-landing-page-app-template" className="btn btn-lg btn-success">Contact Us Now</a>*/}
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="main-carousel overflow-visible"
                                         data-flickity='{ "cellAlign": "center", "contain": true, "prevNextButtons": false, "pageDots":true, "wrapAround":true, "autoPlay":5000, "imagesLoaded":true, "initialIndex":3, "draggable":false }'>
                                        <div className="carousel-cell col-11">
                                            <div className="carousel-cell col-11">
                                                <div className="card card-sm bg-gradient border-0">
                                                    <a href="landing-1.html">
                                                        <img className="card-img-top"
                                                             src="assets/img/overview/landing-1-hero.jpg"
                                                             alt="Landing - One"/>
                                                    </a>
                                                    <div
                                                        className="card-footer d-flex justify-content-between bg-white">
                                                        <a href="landing-1.html" className="h6 m-0">Landing - One</a>
                                                        <a target="_blank" href="landing-1.html" data-toggle="tooltip"
                                                           data-placement="top" title="Open in new tab"><i
                                                            className="icon-popup"></i></a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="carousel-cell col-11">
                                            <div className="card card-sm bg-gradient border-0"><a href="landing-2.html">
                                                <img className="card-img-top"
                                                     src="assets/img/overview/landing-2-hero.jpg"
                                                     alt="Landing - Two"/>
                                            </a>
                                                <div className="card-footer d-flex justify-content-between">
                                                    <a href="landing-2.html" className="h6 m-0">Landing Mock-up Option
                                                        Two</a>
                                                    <a target="_blank" href="landing-2.html" data-toggle="tooltip"
                                                       data-placement="top" title="Open in new tab"><i
                                                        className="icon-popup"></i></a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="carousel-cell col-11">
                                            <div className="card card-sm bg-gradient border-0"><a href="landing-8.html">
                                                <img className="card-img-top"
                                                     src="assets/img/overview/landing-8-hero.jpg"
                                                     alt="Landing - Eight"/>
                                            </a>
                                                <div className="card-footer d-flex justify-content-between">
                                                    <a href="landing-8.html" className="h6 m-0">Landing Mock-up Option
                                                        Eight</a>
                                                    <a target="_blank" href="landing-8.html" data-toggle="tooltip"
                                                       data-placement="top" title="Open in new tab"><i
                                                        className="icon-popup"></i></a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="carousel-cell col-11">
                                            <div className="card card-sm bg-gradient border-0"><a href="landing-9.html">
                                                <img className="card-img-top"
                                                     src="assets/img/overview/landing-9-hero.jpg"
                                                     alt="Landing - Nine"/>
                                            </a>
                                                <div className="card-footer d-flex justify-content-between">
                                                    <a href="landing-9.html" className="h6 m-0">Landing Mock-up Option
                                                        Nine</a>
                                                    <a target="_blank" href="landing-9.html" data-toggle="tooltip"
                                                       data-placement="top" title="Open in new tab"><i
                                                        className="icon-popup"></i></a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="carousel-cell col-11">
                                            <div className="card card-sm bg-gradient border-0"><a
                                                href="app-collection-list.html">
                                                <img className="card-img-top"
                                                     src="assets/img/overview/app-collection-list-hero.jpg"
                                                     alt="Collection - List"/>
                                            </a>
                                                <div className="card-footer d-flex justify-content-between">
                                                    <a href="app-collection-list.html" className="h6 m-0">Mock-up
                                                        Collection -
                                                        List</a>
                                                    <a target="_blank" href="app-collection-list.html"
                                                       data-toggle="tooltip"
                                                       data-placement="top" title="Open in new tab"><i
                                                        className="icon-popup"></i></a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="carousel-cell col-11">
                                            <div className="card card-sm bg-gradient border-0"><a
                                                href="app-docs-landing.html">
                                                <img className="card-img-top"
                                                     src="assets/img/overview/app-docs-landing-hero.jpg"
                                                     alt="Documentation - Landing"/>
                                            </a>
                                                <div className="card-footer d-flex justify-content-between">
                                                    <a href="app-docs-landing.html" className="h6 m-0">Mock-up
                                                        Documentation -
                                                        Landing</a>
                                                    <a target="_blank" href="app-docs-landing.html"
                                                       data-toggle="tooltip"
                                                       data-placement="top" title="Open in new tab"><i
                                                        className="icon-popup"></i></a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="carousel-cell col-11">
                                            <div className="card card-sm bg-gradient border-0"><a
                                                href="app-discussion-list.html">
                                                <img className="card-img-top"
                                                     src="assets/img/overview/app-discussion-list-hero.jpg"
                                                     alt="Discussion - List"/>
                                            </a>
                                                <div className="card-footer d-flex justify-content-between">
                                                    <a href="app-discussion-list.html" className="h6 m-0">Mock-up
                                                        Discussion -
                                                        List</a>
                                                    <a target="_blank" href="app-discussion-list.html"
                                                       data-toggle="tooltip"
                                                       data-placement="top" title="Open in new tab"><i
                                                        className="icon-popup"></i></a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="carousel-cell col-11">
                                            <div className="card card-sm bg-gradient border-0"><a
                                                href="app-profile-social.html">
                                                <img className="card-img-top"
                                                     src="assets/img/overview/app-profile-social-hero.jpg"
                                                     alt="Profile - Social"/>
                                            </a>
                                                <div className="card-footer d-flex justify-content-between">
                                                    <a href="app-profile-social.html" className="h6 m-0">Mock-up Profile
                                                        - Social</a>
                                                    <a target="_blank" href="app-profile-social.html"
                                                       data-toggle="tooltip"
                                                       data-placement="top" title="Open in new tab"><i
                                                        className="icon-popup"></i></a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="carousel-cell col-11">
                                            <div className="card card-sm bg-gradient border-0"><a
                                                href="app-project-detail.html">
                                                <img className="card-img-top"
                                                     src="assets/img/overview/app-project-detail-hero.jpg"
                                                     alt="Project - Detail"/>
                                            </a>
                                                <div className="card-footer d-flex justify-content-between">
                                                    <a href="app-project-detail.html" className="h6 m-0">Mock-up Project
                                                        - Detail</a>
                                                    <a target="_blank" href="app-project-detail.html"
                                                       data-toggle="tooltip"
                                                       data-placement="top" title="Open in new tab"><i
                                                        className="icon-popup"></i></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    {/*<section>*/}
                    {/*<div className="container">*/}
                    {/*<div className="row justify-content-center text-center section-intro">*/}
                    {/*<div className="col-12 col-md-9 col-lg-8">*/}
                    {/*<span className="title-decorative">Show Case</span>*/}
                    {/*<h2 className="display-4">Exciting Possibilities</h2>*/}
                    {/*<span className="lead">Take a look around...</span>*/}

                    {/*</div>*/}
                    {/*</div>*/}
                    {/*<ul className="row feature-list feature-list-sm">*/}
                    {/*<li className="col-12 col-md-4">*/}
                    {/*<a href="pages-landing.html" className="card">*/}
                    {/*<img alt="Image" src="assets/img/overview/landing-9-hero.jpg"*/}
                    {/*className="img-fluid rounded"/>*/}
                    {/*</a>*/}
                    {/*<h5>Past Work</h5>*/}
                    {/*<p>*/}
                    {/*View a select of our previous developments and deliverables*/}
                    {/*</p>*/}
                    {/*<a href="pages-landing.html">Explore Landing Pages &rsaquo;</a>*/}
                    {/*</li>*/}
                    {/*<li className="col-12 col-md-4">*/}
                    {/*<a href="pages-app.html" className="card">*/}
                    {/*<img alt="Image" src="assets/img/overview/app-profile-social-hero.jpg"*/}
                    {/*className="img-fluid rounded"/>*/}
                    {/*</a>*/}
                    {/*<h5>The yellow brick road of software development</h5>*/}
                    {/*<p>*/}
                    {/*A Project process and methodology is necessary to know where and how you're going to*/}
                    {/*get to your goal.*/}
                    {/*</p>*/}
                    {/*<a href="/projectmanagementprocess">Explore Our Process &rsaquo;</a>*/}
                    {/*</li>*/}
                    {/*<li className="col-12 col-md-4">*/}
                    {/*<a href="pages-inner.html" className="card">*/}
                    {/*<img alt="Image" src="assets/img/overview/company-simple-hero.jpg"*/}
                    {/*className="img-fluid rounded"/>*/}
                    {/*</a>*/}
                    {/*<h5>Client Portal</h5>*/}
                    {/*<p>*/}
                    {/*Landing pages are just the beginning. Wingman includes a collection of detailed inner*/}
                    {/*pages to complete your online presence.*/}
                    {/*</p>*/}
                    {/*<a href="pages-inner.html">Explore Inner Pages &rsaquo;</a>*/}
                    {/*</li>*/}
                    {/*</ul>*/}
                    {/*</div>*/}
                    {/*</section>*/}

                    {/*<section className="bg-gradient text-white space-lg">*/}
                    {/*<div className="container">*/}
                    {/*<div className="row justify-content-center">*/}
                    {/*<div className="col-12 col-lg-10">*/}
                    {/*<div className="media">*/}
                    {/*<img alt="Image" src="images/color-lowres.jpg" className="avatar avatar-lg"/>*/}
                    {/*<div className="media-bo  dy">*/}
                    {/*<p className="h2">*/}
                    {/*“We take much pride from working with our clients and delivering first class products.”*/}
                    {/*/!*“Integral integrated Learning, hard-work and an Open-mind, creates Innovation and Extraordinarily Successful Businesses”*!/*/}
                    {/*</p>*/}
                    {/*<span>Jon Leahy, Newtime Systems</span>*/}
                    {/*</div>*/}
                    {/*</div>*/}
                    {/*</div>*/}
                    {/*</div>*/}
                    {/*</div>*/}
                    {/*</section>*/}

                    <section>
                        <div className="container">
                            <div className="row justify-content-center text-center section-intro">
                                <div className="col-12 col-md-9 col-lg-8">
                                    <span className="title-decorative">Keeping you in the loop</span>
                                    <h2 className="display-4">Transparency in motion</h2>
                                    <span className="lead">You get access to great project management overview</span>

                                </div>
                            </div>
                            <div className="row justify-content-around align-items-center">
                                <div className="col-12 col-md-6 col-lg-5 order-md-2">
                                    <ul className="nav" id="myTab" role="tablist">
                                        <li>
                                            <div className="card" id="tab-2" data-toggle="tab" href="#profile"
                                                 role="tab"
                                                 aria-controls="profile" aria-selected="false">
                                                <div className="card-body">
                                                    <h5>A Clear Transparent Process</h5>
                                                    <p>
                                                        We know how frustrating project management visibility can be, so
                                                        we
                                                        provide transparency by giving you access to the
                                                        full project details.
                                                    </p>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="active card" id="tab-1" data-toggle="tab" href="#home"
                                                 role="tab"
                                                 aria-controls="home" aria-selected="true">
                                                <div className="card-body">
                                                    <h5>Online &amp; Available</h5>
                                                    <p>
                                                        You get access to your projects 24/7 with our online task
                                                        management system.
                                                    </p>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="card" id="tab-3" data-toggle="tab" href="#contact"
                                                 role="tab"
                                                 aria-controls="contact" aria-selected="false">
                                                <div className="card-body">
                                                    <h5>Open to questions and requests</h5>
                                                    <p>
                                                        We're friendly so let us have requests and we'll do our best to
                                                        make it happen
                                                    </p>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-12 col-md-6 order-md-1">
                                    <div className="tab-content" id="myTabContent">
                                        <div className="tab-pane fade show active" id="home" role="tabpanel"
                                             aria-labelledby="tab-1">
                                            <img alt="" className="img-fluid box-shadow"
                                                 src="assets/img/graphic-intro-widgets.png"/>
                                        </div>
                                        <div className="tab-pane fade" id="profile" role="tabpanel"
                                             aria-labelledby="tab-2">
                                            <img alt="" className="img-fluid box-shadow"
                                                 src="assets/img/graphic-intro-code.png"/>
                                        </div>
                                        <div className="tab-pane fade" id="contact" role="tabpanel"
                                             aria-labelledby="tab-3">
                                            <img alt="" className="img-fluid box-shadow"
                                                 src="assets/img/graphic-intro-plugins.png"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="bg-gradient p-0 text-white">
                        <svg className="decorative-divider" preserveAspectRatio="none" viewBox=" 0 0 100 100">
                            <polygon fill="#F8F9FB" points="0 0 100 0 100 100"></polygon>
                        </svg>
                        <div className="container space-lg">
                            <div className="row text-center">
                                <div className="col">
                                    <h3 className="h1">Building future solutions</h3>
                                    <a href="/" className="btn btn-lg btn-light" onClick={this.noEmail.bind(this)}>Contact Us
                                        Now</a>
                                </div>
                            </div>
                        </div>
                    </section>

                    <FooterSection/>

                </div>
            </div>
        );
    }
}

