import React, {Component, Fragment} from 'react';
import _ from 'lodash';
import {DeliverableListSummary} from "./DeliverableListSummary";
import {Link} from "react-router-dom";

export class ProjectListDash extends Component {

    constructor(props) {
        super(props)
        this.state = {projects: []}
    }

    calcData(project) {
        let total = Math.abs(project.totalCompleted);
        let totalCompleted = Math.abs(project.total - project.totalCompleted);

        return [
            {name: 'Group A', value: total},
            {name: 'Group B', value: totalCompleted, fill: "#ff0000"}
        ];
    }

    timeLeft(project) {
        return ((project.total - project.totalCompleted).toFixed(1));
    }

    render() {
        const {projects} = this.props;

        let filteredProjects = []

        if (this.props.filterfolder !== undefined) {
            filteredProjects = projects.filter(elem => {
                return elem.folder === this.props.filterfolder;
            })
        } else {
            filteredProjects = projects
        }

        if (filteredProjects.length === 0) {
            return null;
        } else {
            return (
                <Fragment>
                    <tr>
                        <td><h3>{_.upperFirst(this.props.filterfolder)}</h3></td>
                    </tr>
                    {filteredProjects.map(this.renderProject.bind(this))}
                    <tr>
                        <td><br/></td>
                    </tr>
                </Fragment>
            );
        }

    }

    renderProject(project) {
        const title = project.title;

        return (

            <Fragment key={title}>

                <tr className="bg-white">
                    <th scope="row" className="w-50" colSpan="2">
                        <Link className="media align-items-center" to={`/ProjectsFullDetails/${project.id}`}>
                            <img alt="" src={`/assets/img/${project.folder}.png`}
                                 className="avatar rounded mr-3"/>
                            <div className="media-body">
                                <span className="h6 mb-0">{title}</span>
                                <span
                                    className="text-muted">{project.totalCompleted.toFixed(1)} complete / {project.total.toFixed(1)} days</span>
                            </div>
                        </Link>
                    </th>
                    <td className="w-25" colSpan="3">
                        <div className="progress progress-sm">
                            <div className="progress-bar" role="progressbar" aria-valuenow="95"
                                 aria-valuemin="0" aria-valuemax="100"
                                 style={{width: `${project.perccompleted}%`}}/>
                        </div>
                    </td>
                    <td align="right">
                        {this.timeLeft(project)}d left
                    </td>
                    <td></td>
                </tr>

                <DeliverableListSummary project={project} currentFilters={this.props.currentFilters}/>

                <tr>
                    <td><br/></td>
                </tr>

                <tr className="table-divider"/>

            </Fragment>
        );

    }

}
