import React, {Component, Fragment} from 'react';
import TaskType from "./taskType";
import ReactMarkdown from 'react-markdown'

export class TaskListVisualProposal extends Component {

    render() {

    const {Deliverable} = this.props;

    if (!Deliverable) {
      return null;
    }

    return (
      <Fragment>

          <table style={{width: '100%'}}>
              <thead>
                  <tr hidden>
                      <td style={{width: '75%'}}></td>
                      <td style={{width: '25%'}}>Project</td>
                  </tr>
              </thead>
              <tbody>
                 <tr>
                     <td style={{width: '100%'}}>
                         <div className="container">
                             <h2>{Deliverable.title}</h2><br/>
                         </div>
                         </td>
                 </tr>
                 {Deliverable.tasks.map(this.renderTask.bind(this))}
                  <tr>
                      <td style={{width: '75%'}}></td>
                      <td style={{width: '25%'}}></td>
                  </tr>
              </tbody>
          </table>
      </Fragment>
    );
  }

  renderTask(Task) {
    const title = Task.title;
    return (
      <tr key={Task.id}>
        <td style={{width: '75%'}}>
            <div className="container">
                <h4>{title}&nbsp;<TaskType type={Task.type}/></h4>
                <ReactMarkdown source={Task.description} />
                Duration: {Task.time}<br/><br/>
            </div>
        </td>
        <td style={{width: '25%'}}>

            {Task.img !== "" &&
            <img alt="" className="fluid-left" width="200px" src={`/images/${Task.id}.png`}/>
            }

            {Task.includedInQuote === true &&
            <i className="fas fa-check"></i>
            }

        </td>
      </tr>
    );
  }

}
