// NOTE: this example keeps the access token in LocalStorage just because it's simpler
// but in a real application you may want to use cookies instead for better security
import {getURLforProductionOrDev} from "./library/server";

var jwtDecode = require('jwt-decode');

const accessTokenKey = 'accessToken';

export function getAccessToken() {
  return localStorage.getItem(accessTokenKey);
}

export async function login(email, password) {

  const response = await fetch(getURLforProductionOrDev() + '/login', {
    method: 'POST',
    headers: {'content-type': 'application/json'},
    body: JSON.stringify({email, password})
  });
  if (response.ok) {
    const {token} = await response.json();
    // var decoded = jwtDecode(token);
    localStorage.setItem(accessTokenKey, token);
  }
  return response.ok;

}

export function isLoggedIn() {
  return !  !localStorage.getItem(accessTokenKey);
}

export function logout() {
  localStorage.removeItem(accessTokenKey);
}

export function isUserLevel() {
  if (localStorage.getItem(accessTokenKey)) {
    try {
      let token = localStorage.getItem(accessTokenKey)
      var decoded = jwtDecode(token);
      return decoded.level
    } catch (err) {
      return false
    }
  }
  return !  !localStorage.getItem(accessTokenKey);
}
