import React, { Component } from 'react';
import { loadProjects } from './requests';
import { Jumbotron, Button,
    Card, CardImg, CardText, CardBody,
    CardTitle, CardSubtitle } from 'reactstrap';

export class DevelopmentProcessPage extends Component {

  constructor(props) {
    super(props);
    this.state = {Projects: []};
  }

  async componentDidMount() {
    const Projects = await loadProjects();
    this.setState({Projects});
  }

  render() {
    return (
      <div>

          <br/>

          <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                  <li className="breadcrumb-item"><a href="/">Home</a></li>
                  <li className="breadcrumb-item active" aria-current="page">Development Process</li>
              </ol>
          </nav>

          <div className="row">
              <div className="col-sm-4">
                  <Card>
                      <CardImg top width="100%" src="https://placeholdit.imgix.net/~text?txtsize=33&txt=318%C3%97180&w=318&h=180" alt="Card image cap" />
                      <CardBody>
                          <CardTitle>Transparency</CardTitle>
                          <CardSubtitle>Project Updates "clearly" as they should be</CardSubtitle>
                          <CardText>View full project details quickly and stay in the loop with email alerts.</CardText>
                          <Button>More</Button>
                      </CardBody>
                  </Card>
              </div>

              <div className="col-sm-4">
                  <Card>
                      <CardImg top width="100%" src="https://placeholdit.imgix.net/~text?txtsize=33&txt=318%C3%97180&w=318&h=180" alt="Card image cap" />
                      <CardBody>
                          <CardTitle>Development Processes</CardTitle>
                          <CardSubtitle>How it works</CardSubtitle>
                          <CardText>How we'll handle your project. See our processes and procedures.</CardText>
                          <Button>Button</Button>
                      </CardBody>
                  </Card>
              </div>

              <div className="col-sm-4">
                  <Card>
                      <CardImg top width="100%" src="https://placeholdit.imgix.net/~text?txtsize=33&txt=318%C3%97180&w=318&h=180" alt="Card image cap" />
                      <CardBody>
                          <CardTitle>Show Case</CardTitle>
                          <CardSubtitle>Card subtitle</CardSubtitle>
                          <CardText>Here are some projects we've completed in the past. Take a look.</CardText>
                          <Button>Button</Button>
                      </CardBody>
                  </Card>
              </div>

          </div>

          <br/>
          <br/>

          <Jumbotron>
              <h1 className="display-3">TransparencyPage</h1>
              <p className="lead">We offer custom bespoke software development using modern software languages and tools, including React, Javascript and Node.js</p>
              <hr className="my-2" />
              <p>New modern software development including agile mythologies, automated testing and build systems, when required.</p>
              <p className="lead">
                  <Button color="primary">Learn More</Button>
              </p>
          </Jumbotron>

          <br/>
          <br/>

          <Jumbotron>
              <h1 className="display-5">Get in Touch</h1>
              <p className="lead">If you have a new project or require modifications to an existing one then get in touch and talk through the details.</p>
              <hr className="my-2" />
              <p>You may have a project in trouble or in need of assistance with we can also help.</p>
          </Jumbotron>

          <br/>
          <br/>

      </div>
    );
  }
}

