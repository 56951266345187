import React, {Component, Fragment} from 'react';
import TaskType from "./taskType";
import ReactMarkdown from 'react-markdown'
import HighlightText from "./HighlightText";

import {connect} from "react-redux";
import {openModal} from "./components/Modals/modalActions";
import {faPencilAlt, faPlus} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const actions = {openModal}

class TaskListProposal extends Component {

    render() {

        const {Deliverable} = this.props;
        const {openModal} = this.props;

        if (!Deliverable) {
            return null;
        }

        let bHideCompleted = this.props.currentFilters.data[0]["hideCompleted"]

        if (bHideCompleted) {
            const totalUncompleted = Deliverable.tasks.reduce((acculator, task) => acculator + (task.complete ? 0 : 1), 0);
            if (totalUncompleted === 0) {
                return null
            }
        }

        return (
            <Fragment>

                <table className="table" style={{width: '100%'}}>
                    <thead className="bg-secondary">
                    <tr>
                        <td style={{width: '2%'}}></td>
                        <td style={{width: '70%'}}>
                            {Deliverable.title}
                            {Deliverable.description &&
                            <div>
                                {Deliverable.description}
                            </div>
                            }
                        </td>
                        <td style={{width: '15%'}} className="text-right">Duration: {Deliverable.total.toFixed(1)} d
                        </td>
                        <td style={{width: '5%'}} className="text-right">Importance</td>
                        <td style={{width: '5%'}} className="text-right">
                            <button className="btn btn-sm btn-outline-primary"
                                    onClick={() => openModal('EditDeliverableModal', {deliverableId: Deliverable.id})}
                            >
                                <FontAwesomeIcon icon={faPencilAlt}/>
                            </button>
                        </td>
                    </tr>
                    </thead>
                    <tbody>
                    {Deliverable.tasks.map(this.renderTask.bind(this))}
                    {this.renderAddTask(Deliverable.id)}
                    </tbody>
                </table>
            </Fragment>
        );

    }

    renderTask(Task) {

        if (this.bHideCompleted) {
            if (Task.complete) {
                return null
            }
        }

        const title = Task.title;
        const {openModal} = this.props;
        return (
            <tr key={Task.id} className="text-small">
                <td style={{width: '2%'}}>
                    <HighlightText completed={Task.complete} showCheck={true} showText={false}>{title}</HighlightText>
                </td>
                <td style={{width: '70%'}}>
                    <HighlightText completed={Task.complete} showCheck={false} showText={true}>{title}</HighlightText>
                    &nbsp;
                    <TaskType type={Task.type}/>
                    &nbsp;
                    <br/>
                    {Task.description !== "" &&
                    <div>
                        <ReactMarkdown source={Task.description}/>
                    </div>
                    }
                </td>
                <td style={{width: '15%'}} className="text-right">
                    {Task.time} d
                </td>
                <td style={{width: '15%'}} className="text-right">
                    {Task.importance}
                </td>
                <td style={{width: '5%'}} className="text-right">
                    <button className="btn btn-sm btn-outline-primary"
                            style={ {padding: "0.11rem 0.15rem", fontSize: "0.875rem", lineHeight: "1rem"}}
                            onClick={() => openModal('EditTaskModal', {taskId: Task.id})}
                    >
                        <FontAwesomeIcon icon={faPencilAlt}/>
                    </button>
                </td>
            </tr>
        );
    }

    renderAddTask(deliverableId) {
        const {openModal} = this.props;
        return (
            <tr key={deliverableId} className="text-small">
                <td style={{width: '2%'}}>
                </td>
                <td style={{width: '70%'}}>
                </td>
                <td style={{width: '15%'}} className="text-right">
                </td>
                <td style={{width: '5%'}} className="text-right">
                </td>
                <td style={{width: '5%'}} className="text-right">
                    <button className="btn btn-sm btn-outline-primary"
                            onClick={() => openModal('NewTaskModal', {deliverableId: deliverableId})}
                    >
                        <FontAwesomeIcon icon={faPlus}/>
                    </button>
                </td>
            </tr>
        );
    }

}

export default connect(null, actions)(TaskListProposal);
