import React, {Component, Fragment} from 'react';

class TaskType extends Component {

    iconType() {

        switch (this.props.type) {
            case "BG": // Bug
                return "far fa-bug";
            case "NF": // Additional cost
                return "far fa-shopping-basket";
            case "DC":
                return "far fa-file-alt";
            default:
                return "";
        }

    }

    render() {
        return (
            <Fragment>
                <i className={this.iconType()}></i>
            </Fragment>
        );
    }

};

export default TaskType;
