import React, { Component } from 'react';
import { ProjectList } from './ProjectList';
import { loadCompany } from "./requests";

export class CompanyDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {company: null};
  }

  async componentDidMount() {
    const {companyId} = this.props.match.params;
    const company = await loadCompany(companyId);
    this.setState({company});
  }

  render() {
    const {company} = this.state;
    if (!company) {
      return null;
    }
    return (
        <div>
            <div className="main-container">
                <nav aria-label="breadcrumb" className="bg-primary text-white">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><a href="/">Home</a>
                                    </li>
                                    <li className="breadcrumb-item"><a href="/companies">{company.name}</a>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">Projects</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </nav>

                <section>
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col">
                                <h1 className="h2 mb-2">{company.name}: Project - Full List</h1>
                                <span>Results 1 - { company.Projects.length } of { company.Projects.length }</span>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="flush-with-above">
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <div className="table-responsive">
                                    <table className="table table-borderless align-items-center">
                                        <tbody>

                                        <ProjectList Projects={company.Projects} filterfolder="inbox" />
                                        <br/>

                                        <ProjectList Projects={company.Projects} filterfolder="draft" />
                                        <br/>

                                        <ProjectList Projects={company.Projects} filterfolder="archive" />
                                        <br/>

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </div>
        </div>


      //           {/*<section className="space-sm">*/}
      //               {/*<div className="container">*/}
      //
      //                   {/*<div className="row">*/}
      //                       {/*<div className="col-12 col-md-9 mb-1 order-md-2">*/}
      //
      //                           {/*<div className="card card-sm">*/}
      //                               {/*<div*/}
      //                                   {/*className="card-header bg-secondary d-flex justify-content-between align-items-center">*/}
      //                                   {/*<Jumbotron>*/}
      //                                       {/*<h2 className="title">Company: {company.name}</h2><br/>*/}
      //                                       {/*<div className="row">*/}
      //                                           {/*<div className="col-sm-6">*/}
      //                                               {/*<div className="box">{company.description}</div>*/}
      //                                           {/*</div>*/}
      //                                           {/*<div className="col-sm-4">*/}
      //                                               {/*<img src={`/companyimages/${company.id}.png`} style={{width: '300px'}} />*/}
      //                                           {/*</div>*/}
      //                                       {/*</div>*/}
      //                                   {/*</Jumbotron>*/}
      //                               {/*</div>*/}
      //                           {/*</div>*/}
      //
      //                           {/*<div className="card card-sm">*/}
      //                               {/*<div*/}
      //                                   {/*className="card-header bg-secondary d-flex justify-content-between align-items-center">*/}
      //                                   {/*<div>*/}
      //                                       {/*<h6>Projects</h6>*/}
      //                                   {/*</div>*/}
      //                                   {/*<form className="d-flex align-items-center">*/}
      //                                       {/*<span className="mr-2 text-muted text-small text-nowrap">Sort by:</span>*/}
      //                                       {/*<select className="custom-select">*/}
      //                                           {/*<option value="old-new" selected>Newest</option>*/}
      //                                           {/*<option value="new-old">Popular</option>*/}
      //                                           {/*<option value="most">Most Comments</option>*/}
      //                                       {/*</select>*/}
      //                                   {/*</form>*/}
      //                               {/*</div>*/}
      //                               {/*<div className="list-group list-group-flush">*/}
      //
      //                                   {/*<ProjectList Projects={company.Projects} filterfolder="archive" />*/}
      //
      //                                   {/*<div className="card-header bg-secondary">*/}
      //                                       {/*<h6>Draft</h6>*/}
      //                                   {/*</div>*/}
      //                                   {/*<ProjectList Projects={company.Projects} filterfolder="draft" />*/}
      //
      //                                   {/*<div className="card-header bg-secondary">*/}
      //                                       {/*<h6>Archived</h6>*/}
      //                                   {/*</div>*/}
      //                                   {/*<ProjectList Projects={company.Projects} filterfolder="archive" />*/}
      //
      //                               {/*</div>*/}
      //                           {/*</div>*/}
      //                           {/*<div className="d-flex justify-content-center">*/}
      //                               {/*<nav aria-label="Page navigation example">*/}
      //                                   {/*<ul className="pagination">*/}
      //                                       {/*<li className="page-item disabled">*/}
      //                                           {/*<a className="page-link" href="#" aria-label="Previous">*/}
      //                                           {/*<span aria-hidden="true"><i className="icon-chevron-left"></i>*/}
      //                                           {/*</span>*/}
      //                                               {/*<span className="sr-only">Previous</span>*/}
      //                                           {/*</a>*/}
      //                                       {/*</li>*/}
      //                                       {/*<li className="page-item active"><a className="page-link" href="#">1</a>*/}
      //                                       {/*</li>*/}
      //                                       {/*<li className="page-item"><a className="page-link" href="#">2</a>*/}
      //                                       {/*</li>*/}
      //                                       {/*<li className="page-item"><a className="page-link" href="#">3</a>*/}
      //                                       {/*</li>*/}
      //                                       {/*<li className="page-item">*/}
      //                                           {/*<a className="page-link" href="#" aria-label="Next">*/}
      //                                           {/*<span aria-hidden="true"><i className="icon-chevron-right"></i>*/}
      //                                           {/*</span>*/}
      //                                               {/*<span className="sr-only">Next</span>*/}
      //                                           {/*</a>*/}
      //                                       {/*</li>*/}
      //                                   {/*</ul>*/}
      //                               {/*</nav>*/}
      //                           {/*</div>*/}
      //                       {/*</div>*/}
      //                       {/*<div className="col-12 col-md-3 mb-1 order-md-1">*/}
      //                           {/*<div className="card">*/}
      //                               {/*<div className="card-body">*/}
      //                                   {/*<form className="mb-4">*/}
      //                                       {/*<input className="form-control" placeholder="Search" type="search"*/}
      //                                              {/*name="search-conversations"/>*/}
      //                                   {/*</form>*/}
      //                                   {/*<nav className="nav flex-md-column">*/}
      //                                       {/*<a href="#" className="nav-link active">All</a>*/}
      //                                       {/*<a href="#" className="nav-link">*/}
      //                                           {/*<span className="badge badge-indicator badge-success mr-1">&nbsp;</span>On*/}
      //                                           {/*track</a>*/}
      //                                       {/*<a href="#" className="nav-link">*/}
      //                                           {/*<span className="badge badge-indicator badge-warning mr-1">&nbsp;</span>Falling*/}
      //                                           {/*behind</a>*/}
      //                                       {/*<a href="#" className="nav-link">*/}
      //                                           {/*<span className="badge badge-indicator badge-danger mr-1">&nbsp;</span>Needs*/}
      //                                           {/*attention</a>*/}
      //                                       {/*<a href="#" className="nav-link">*/}
      //                                           {/*<span*/}
      //                                               {/*className="badge badge-indicator badge-secondary mr-1">&nbsp;</span>Archived</a>*/}
      //                                   {/*</nav>*/}
      //                               {/*</div>*/}
      //                           {/*</div>*/}
      //                           {/*<div className="card card-sm">*/}
      //                               {/*<div className="card-header">*/}
      //                                   {/*<span className="h6">Categories</span>*/}
      //                               {/*</div>*/}
      //                               {/*<div className="list-group list-group-flush">*/}
      //
      //                                   {/*<a className="list-group-item d-flex justify-content-between" href="#">*/}
      //                                       {/*<div>*/}
      //                                           {/*<i className="icon-area-graph mr-1"></i>*/}
      //                                           {/*<span>Analytics</span>*/}
      //                                       {/*</div>*/}
      //                                       {/*<div>*/}
      //                                           {/*<i className="icon-chevron-right"></i>*/}
      //                                       {/*</div>*/}
      //                                   {/*</a>*/}
      //
      //                                   {/*<a className="list-group-item d-flex justify-content-between" href="#">*/}
      //                                       {/*<div>*/}
      //                                           {/*<i className="icon-power-plug mr-1"></i>*/}
      //                                           {/*<span>API Integration</span>*/}
      //                                       {/*</div>*/}
      //                                       {/*<div>*/}
      //                                           {/*<i className="icon-chevron-right"></i>*/}
      //                                       {/*</div>*/}
      //                                   {/*</a>*/}
      //
      //                                   {/*<a className="list-group-item d-flex justify-content-between" href="#">*/}
      //                                       {/*<div>*/}
      //                                           {/*<i className="icon-credit-card mr-1"></i>*/}
      //                                           {/*<span>Billing</span>*/}
      //                                       {/*</div>*/}
      //                                       {/*<div>*/}
      //                                           {/*<i className="icon-chevron-right"></i>*/}
      //                                       {/*</div>*/}
      //                                   {/*</a>*/}
      //
      //                                   {/*<a className="list-group-item d-flex justify-content-between" href="#">*/}
      //                                       {/*<div>*/}
      //                                           {/*<i className="icon-code mr-1"></i>*/}
      //                                           {/*<span>Development</span>*/}
      //                                       {/*</div>*/}
      //                                       {/*<div>*/}
      //                                           {/*<i className="icon-chevron-right"></i>*/}
      //                                       {/*</div>*/}
      //                                   {/*</a>*/}
      //
      //                                   {/*<a className="list-group-item d-flex justify-content-between" href="#">*/}
      //                                       {/*<div>*/}
      //                                           {/*<i className="icon-shield mr-1"></i>*/}
      //                                           {/*<span>Security</span>*/}
      //                                       {/*</div>*/}
      //                                       {/*<div>*/}
      //                                           {/*<i className="icon-chevron-right"></i>*/}
      //                                       {/*</div>*/}
      //                                   {/*</a>*/}
      //
      //                                   {/*<a className="list-group-item d-flex justify-content-between" href="#">*/}
      //                                       {/*<div>*/}
      //                                           {/*<i className="icon-folder mr-1"></i>*/}
      //                                           {/*<span>Storage</span>*/}
      //                                       {/*</div>*/}
      //                                       {/*<div>*/}
      //                                           {/*<i className="icon-chevron-right"></i>*/}
      //                                       {/*</div>*/}
      //                                   {/*</a>*/}
      //
      //                               {/*</div>*/}
      //                           {/*</div>*/}
      //                           {/*<div className="card card-borderless bg-secondary">*/}
      //                               {/*<a className="card-body" href="#">*/}
      //                                   {/*<div className="d-flex justify-content-between mb-3">*/}
      //                                       {/*<img alt="Image" src="assets/img/logo-w-gradient.svg"/>*/}
      //                                       {/*<span className="title-decorative">Sponsor</span>*/}
      //                                   {/*</div>*/}
      //                                   {/*<span className="h6">A robust suite of styled elements and pages</span>*/}
      //                               {/*</a>*/}
      //                           {/*</div>*/}
      //                       {/*</div>*/}
      //                   {/*</div>*/}
      //               {/*</div>*/}
      //           {/*</section>*/}
      //           {/*<footer className="footer-short">*/}
      //               {/*<div className="container">*/}
      //                   {/*/!*<hr>*!/*/}
      //                       {/*<nav className="row justify-content-between align-items-center">*/}
      //                           {/*<div className="col-auto">*/}
      //                               {/*<ul className="list-inline">*/}
      //                                   {/*<li className="list-inline-item">*/}
      //                                       {/*<a href="#">*/}
      //                                           {/*<img alt="Image" src="assets/img/logo-gray.svg"/>*/}
      //                                       {/*</a>*/}
      //                                   {/*</li>*/}
      //                                   {/*<li className="list-inline-item">*/}
      //                                       {/*<a href="#">Overview</a>*/}
      //                                   {/*</li>*/}
      //                                   {/*<li className="list-inline-item">*/}
      //                                       {/*<a href="#">Documentation</a>*/}
      //                                   {/*</li>*/}
      //                                   {/*<li className="list-inline-item">*/}
      //                                       {/*<a href="#">Changelog</a>*/}
      //                                   {/*</li>*/}
      //                               {/*</ul>*/}
      //                           {/*</div>*/}
      //                           {/*<div className="col-auto text-sm-right">*/}
      //                               {/*<ul className="list-inline">*/}
      //                                   {/*<li className="list-inline-item">*/}
      //                                       {/*<a href="#"><i className="socicon-twitter"></i></a>*/}
      //                                   {/*</li>*/}
      //                                   {/*<li className="list-inline-item">*/}
      //                                       {/*<a href="#"><i className="socicon-facebook"></i></a>*/}
      //                                   {/*</li>*/}
      //                               {/*</ul>*/}
      //                           {/*</div>*/}
      //                       {/*</nav>*/}
      //                       {/*<div className="row">*/}
      //                           {/*<div className="col">*/}
      //                               {/*<small>&copy; 2018 Medium Rare All Rights Reserved</small>*/}
      //                           {/*</div>*/}
      //                       {/*</div>*/}
      //                   {/*/!*</hr>*!/*/}
      //               {/*</div>*/}
      //           {/*</footer>*/}
      //       {/*</div>*/}
      //
      //       {/*<div className="main-container">*/}
      //
      //     {/*<nav aria-label="breadcrumb" role="navigation" className="bg-primary text-white">*/}
      //         {/*<div className="container">*/}
      //             {/*<div className="row justify-content-center">*/}
      //                 {/*<div className="col">*/}
      //                     {/*<ol className="breadcrumb">*/}
      //                         {/*<li className="breadcrumb-item"><a href="index.html">Home</a>*/}
      //                         {/*</li>*/}
      //                         {/*<li className="breadcrumb-item"><a href="pages-app.html">App Pages</a>*/}
      //                         {/*</li>*/}
      //                         {/*<li className="breadcrumb-item active" aria-current="page">Setting up API end-points</li>*/}
      //                     {/*</ol>*/}
      //                 {/*</div>*/}
      //             {/*</div>*/}
      //         {/*</div>*/}
      //     {/*</nav>*/}
      //
      //     {/*<br/>*/}
      //
      //   {/*</div>*/}
      // {/*</div>*/}

    );
  }
}
