import React from 'react'
import {connect} from "react-redux";
import {Formik} from "formik";
import {newTask} from "../../../actions";

class TaskNewForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            deliverableId: this.props.currentModals.modalProps.deliverableId,
        };
    }

    render() {
        return (

            <div style={{width: '600px'}}>

                <Formik
                    initialValues={{
                        title: "",
                        description: "",
                        time: 0.5,
                        importance: 1
                    }}

                    onSubmit={values => {
                        console.log("-----", JSON.stringify(this.state))
                        this.props.dispatch(
                            newTask(
                                values.title,
                                values.description,
                                this.state.deliverableId,
                                values.time,
                                values.importance,
                            )
                        )
                        ;
                    }}

                    // validate={ values => {
                    //     let errors= {}
                    //     if (!values.title) {
                    //         errors.title = "please enter a name"
                    //     }
                    //     if (!values.duration) {
                    //         errors.duration= "please enter a duration"
                    //     }
                    //     return errors
                    // }}

                >
                    {
                        ({handleSubmit, handleChange, values, errors, handleBlur, touched}) => (
                            <form onSubmit={handleSubmit}>

                                <div className="form-group">
                                    <label htmlFor="exampleInputEmail1">Deliverable Title</label>
                                    <input
                                        className="form-control"
                                        onChange={handleChange}
                                        value={values.title}
                                        type="text"
                                        name="title"
                                        placeholder="Enter your deliverable title"
                                        onBlur={handleBlur}
                                    />
                                    <small id="emailHelp" className="form-text text-muted">A Deliverable title</small>
                                </div>

                                <div className="form-group">
                                    <label htmlFor="exampleInputEmail1">Description</label>
                                    <textarea
                                        className="form-control textarea"
                                        onChange={handleChange}
                                        value={values.description}
                                        name="description"
                                        placeholder="Enter your description"
                                        onBlur={handleBlur}
                                    />
                                    <small id="emailHelp" className="form-text text-muted">A nice description
                                    </small>
                                </div>

                                <div className="form-group">
                                    <label htmlFor="exampleInputEmail1">Time to complete</label>
                                    <input
                                        className="form-control"
                                        onChange={handleChange}
                                        value={values.time}
                                        type="text"
                                        name="time"
                                        placeholder="Enter your time"
                                        onBlur={handleBlur}
                                    />
                                    <small id="emailHelp" className="form-text text-muted">In days</small>
                                </div>

                                <div className="form-group">
                                    <label htmlFor="exampleInputEmail1">Importance</label>
                                    <input
                                        className="form-control"
                                        onChange={handleChange}
                                        value={values.importance}
                                        type="text"
                                        name="importance"
                                        placeholder="Importance 1 to 5"
                                        onBlur={handleBlur}
                                    />
                                    <small id="emailHelp" className="form-text text-muted">Importance 1 to 5</small>
                                </div>

                                <div className="row">
                                    <div className="col-sm-6">
                                        <button type="submit" className="btn btn-primary pull-right">Save</button>
                                        &nbsp;
                                        <button onClick={() => { this.props.onCancel() }} className="btn btn-secondary pull-right">Cancel</button>
                                    </div>
                                </div>

                                {/*projectId: { projectId }*/}

                                {
                                    errors.title &&
                                    <div style={{color: 'red'}}>
                                        {errors.title}
                                    </div>
                                }
                            </form>
                        )

                    }
                </Formik>

            </div>
        )
    }

};

const mapStateToProps = (state) => ({
    currentModals: state.modals
});

export default connect(mapStateToProps, null)(TaskNewForm);