import { combineReducers } from 'redux'
import deliverables from './deliverables';
import tasks from "./tasks";
import project from "./project";
import filters from "./filters";

const rootReducer = combineReducers({
    deliverables,
    tasks,
    project,
    filters
});

export default rootReducer;
