import React, {Component} from 'react';
import {loadProject} from './requests';
import {DeliverableList} from "./DeliverableList";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFilePdf} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import {CompletedSquares} from "./CompletedSquares";
import {getURLforProductionOrDev} from "./library/server";
import {QuoteSidebar} from "./QuoteSidebar";

export class ProjectQuoteDetails extends Component {

    constructor(props) {
        super(props);
        this.state = {Project: null};
    }

    async componentDidMount() {
        const {ProjectId} = this.props.match.params;
        const Project = await loadProject(ProjectId);
        this.setState({Project});
    }

    calcData() {
        let total = Math.abs(this.state.Project.totalCompleted);
        let totalCompleted = Math.abs(this.state.Project.total - this.state.Project.totalCompleted);
        return [
            {name: 'Group A', value: total},
            {name: 'Group B', value: totalCompleted, fill: "#ff0000"}
        ];
    }

    timeLeft() {
        return ((this.state.Project.total - this.state.Project.totalCompleted).toFixed(1));
    }

    getFile() {

        axios({
            url: getURLforProductionOrDev() + "/project",
            method: 'post',
            responseType: 'blob',
            data: {
                projectId: this.state.Project.id
            }
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'report.pdf');
            document.body.appendChild(link);
            link.click();
        });

    }

    getFileQuote() {

        axios({
            url: getURLforProductionOrDev() + "/quote",
            method: 'post',
            responseType: 'blob',
            data: {
                projectId: this.state.Project.id
            }
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'quote.pdf');
            document.body.appendChild(link);
            link.click();
        });

    }

    render() {
        const {Project} = this.state;
        if (!Project) {
            return null;
        }
        return (
            <div>
                <div className="main-container">
                    <nav aria-label="breadcrumb" className="bg-primary text-white">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><a href="/">Home</a>
                                        </li>
                                        <li className="breadcrumb-item"><a href="/projects">Projects</a>
                                        </li>
                                        <li className="breadcrumb-item active" aria-current="page">{Project.title}</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </nav>

                    <section className="bg-white space-sm">
                        <div className="container">
                            <div className="row justify-content-between">
                                <div className="col">
                                    <div className="media align-items-center">
                                        <img alt="" src={`/assets/img/${Project.folder}.png`}
                                             className="avatar avatar-lg avatar-square rounded"/>
                                        <div className="media-body">
                                            <h1 className="mb-0">{Project.title}</h1>
                                            <h2 className="lead mb-2">{Project.company.name}</h2>
                                            <div className="d-flex align-items-center">
                                                <span className="badge badge-info mr-3">{Project.folder}</span>
                                                <ul className="list-inline text-small d-inline-block">
                                                    <li className="list-inline-item"><i
                                                        className="icon-user mr-1"></i> 4
                                                    </li>
                                                    <li className="list-inline-item"><i
                                                        className="icon-message mr-1"></i> 19
                                                    </li>
                                                    <li className="list-inline-item"><i
                                                        className="icon-attachment mr-1"></i> 12
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-auto">
                                    {/*<button className="btn btn-outline-secondary"><i*/}
                                    {/*className="icon-download mr-1"></i> Download PDF Report*/}
                                    {/*</button>*/}
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="flush-with-above space-0">
                        <div className="bg-white">
                            <div className="container">
                                <div className="row">
                                    <div className="col">
                                        <ul className="nav nav-tabs" id="myTab" role="tablist">
                                            <li className="nav-item">
                                                <a className="nav-link" id="overview-tab"
                                                   a href="#projectdetails"
                                                   onClick={() => {
                                                       this.props.history.push(`/Projects/${this.state.Project.id}`)
                                                   }}>Project Details</a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link active" id="billing-tab"
                                                   a href="#billing"
                                                   onClick={() => {
                                                       this.props.history.push(`/ProjectsQuoteDetails/${this.state.Project.id}`)
                                                   }}>Quote
                                                    Details</a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link" id="billing-tab"
                                                   a href="#billing"
                                                   onClick={() => {
                                                       this.props.history.push(`/ProjectsFullDetails/${this.state.Project.id}`)
                                                   }}>Full Project
                                                    Details</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    {/*<section className="space-sm">*/}
                    {/*    <div className="container">*/}
                    {/*<ProjectStatusBar/>*/}
                    {/*    </div></section>*/}

                    <section className="space-sm">
                        <div className="container">
                            <div className="row justify-content-between">
                                <div className="col-12 col-md-8">

                                    <div className="card">
                                        <div className="card-header bg-secondary">
                                            <h4>Deliverables</h4>
                                        {/*    <button className="btn btn-secondary" type="button"*/}
                                        {/*            data-target="#deliverable-add" data-toggle="collapse"*/}
                                        {/*            aria-expanded="false" aria-controls="deliverable-add">*/}
                                        {/*        Create a new Deliverable*/}
                                        {/*    </button>*/}
                                        </div>
                                        {/*<div className="collapse" id="deliverable-add">*/}
                                        {/*    /!*<DeliverableNewComponent projectId={Project.id}/>*!/*/}
                                        {/*</div>*/}
                                        <DeliverableList Project={Project}/>
                                    </div>

                                    <div className="card">
                                        <div className="card-header bg-secondary">
                                            <button className="btn btn-secondary" type="button"
                                                    data-target="#comment-reply" data-toggle="collapse"
                                                    aria-expanded="false" aria-controls="comment-reply">Post a reply
                                            </button>
                                        </div>
                                        <div className="collapse" id="comment-reply">
                                            <form className="card-body">
                                                <div className="form-group">
                                                    <textarea className="form-control from-control-lg"
                                                              id="comment-reply-text" name="comment-reply" rows="4"
                                                              placeholder="Type your reply here"></textarea>
                                                </div>
                                                <div className="mb-2">
                                                    <div className="custom-control custom-checkbox">
                                                        <input type="checkbox" className="custom-control-input"
                                                               name="notify-reply" id="check-notify"/>
                                                        <label className="custom-control-label text-small"
                                                               htmlFor="check-notify">Notify me when someone
                                                            replies</label>
                                                    </div>
                                                </div>
                                                <div className="d-flex align-items-center">
                                                    <button type="submit" className="btn btn-success mr-3">Submit
                                                        reply
                                                    </button>
                                                    <a href="#comment-reply" className="text-small text-muted"
                                                       data-toggle="collapse" aria-expanded="true"
                                                       aria-controls="comment-reply">Cancel</a>
                                                </div>
                                            </form>
                                        </div>

                                        <ul className="list-group list-group-flush list-group-comments">
                                            <li className="list-group-item py-4">
                                                <div className="media">
                                                    <img alt="" src="/assets/img/avatar-female-2.jpg"
                                                         className="avatar avatar-sm"/>
                                                    <div className="media-body">
                                                        <div className="mb-2">
                                                            <span className="h6 mb-0">Jon Leahy</span>
                                                            <span className="text-muted">Newtime Systems</span>
                                                        </div>
                                                        <p>
                                                            Discussion locked
                                                        </p>
                                                        <div className="d-flex align-items-center">
                                                            <div className="mr-2">
                                                                <button
                                                                    className="btn btn-sm btn-outline-primary">Reply
                                                                </button>
                                                                <button className="btn btn-sm btn-outline-primary"><i
                                                                    className="icon-thumbs-up"></i>&nbsp;(2)
                                                                </button>
                                                            </div>
                                                            <small className="text-muted">-st --- ---- • --:--am</small>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>

                                        </ul>
                                    </div>

                                </div>
                                <div className="col-12 col-md-4">

                                    <div className="card">
                                        <button type="button"
                                                className="btn btn-primary"
                                                onClick={() => {
                                                    this.getFileQuote()
                                                }}
                                        >
                                            <br/>
                                            <FontAwesomeIcon icon={faFilePdf}/>&nbsp;
                                            PDF Quote
                                            <br/>
                                            <br/>
                                        </button>
                                    </div>

                                    <div className="card">
                                        <ul className="list-group list-group-flush">
                                            <li className="list-group-item">
                                                <div className="d-flex justify-content-between">
                                                    <div><i className="icon-text-document mr-1"></i> Created</div>
                                                    <span>--/--/----</span>
                                                </div>
                                            </li>
                                            <li className="list-group-item">
                                                <div className="d-flex justify-content-between">
                                                    <div><i className="icon-edit mr-1"></i> Last updated</div>
                                                    <span>--/--/----</span>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>

                                    <div className="card">
                                        <div className="card-header d-flex justify-content-between">
                                            <div>
                                                <span className="h6">Quote</span>
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <QuoteSidebar total={this.state.Project.total.toFixed(1)}
                                                              totalCompleted={this.state.Project.totalCompleted.toFixed(1)}/>
                                        </div>
                                    </div>

                                    <div className="card">
                                        <div className="card-header d-flex justify-content-between">
                                            <div>
                                                <span className="h6">Days</span>
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <CompletedSquares total={this.state.Project.total.toFixed(1)}
                                                              totalCompleted={this.state.Project.totalCompleted.toFixed(1)}/>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </section>

                </div>
            </div>

        );
    }
}
