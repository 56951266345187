import { ApolloClient, ApolloLink, HttpLink, InMemoryCache } from 'apollo-boost';
import gql from 'graphql-tag';
import {getAccessToken, isLoggedIn } from "./auth";
import jsonAddTotals from "./jsonAddTotals";
import {getURLforProductionOrDev} from "./library/server";
import { onError } from 'apollo-link-error';

const endpointURL = getURLforProductionOrDev() + "/graphql";

const authLink = new ApolloLink((operation, forward) => {
    if (isLoggedIn()) {
        operation.setContext({
            headers: {
                'authorization':  'Bearer ' + getAccessToken()
            }
        });
        return forward(operation);
    }
});

const errorLink = onError(({ networkError, graphQLErrors }) => {
    if (graphQLErrors) {
        graphQLErrors.map(({ message, locations, path }) =>
            console.log(
                `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
            ),
        );
    }
    if (networkError) console.log(`[Network error]: ${networkError}`);
});

const client = new ApolloClient({
    link: ApolloLink.from([
        errorLink,
        authLink,
        new HttpLink({uri: endpointURL})
    ]),
    cache: new InMemoryCache()
});


const DeliverableDetailShortFragment = gql`
    fragment DeliverableDetailShort on Deliverable {
        id
        title
        includedInQuote
        additionalCost
        completed
        tasks {
            id
            title
            time
            complete
            includedInQuote
            additionalCost
            type
            description
            img
            importance
            customerRelated
            deliverable {
                id
            }
        }
    }
`;

const ProjectDetailFragment = gql`
    fragment ProjectDetail on Project {
        id
        company {
            id
            name
            contact
        }
        title
        folder
        perccompleted
        deliverables {
            ...DeliverableDetailShort
        }
        devCompany {
            id
            name
        }
        description
    }
    ${DeliverableDetailShortFragment}
`;

const DeliverableDetailFragment = gql`
    fragment DeliverableDetail on Deliverable {
        id
        title
        includedInQuote
        additionalCost
        completed
        description
        tasks {
            id
            title
            time
            complete
            includedInQuote
            additionalCost
            type
            description
            img
            importance
            customerRelated
            deliverable {
                id
            }
        }
        project {
            id
        }

    }
`;

const companyDetailFragment = gql`
    fragment CompanyDetail on Company {
        id
        name
        projects {
            id
            title
            perccompleted
            folder
            deliverables {
                ...DeliverableDetailShort
            }
        }
        description
        contact
    }
  ${DeliverableDetailShortFragment}
`;

const taskDetailFragment = gql`
    fragment TaskDetail on Task {
        id
        title
        description
        time
        type
        img
        importance
        deliverable {
            id
        }
    }
`;

const companyQuery = gql`
  query CompanyQuery($id: ID!) {
    company (id: $id) {
        ...CompanyDetail
    }
  }
  ${companyDetailFragment}
`;

const companiesQuery = gql`
    query CompaniesQuery {
        companies {
            ...CompanyDetail
        }
    }
    ${companyDetailFragment}
`;

const projectQuery = gql`
  query ProjectQuery($id: ID!) {
    project (id: $id) {
        id
        title
        folder
        perccompleted
        description
        folder
        company {
            id
            name
        }
        devCompany {
            id
            name
        }
        deliverables {
            ...DeliverableDetail
        }
        description
    }
  }
  ${DeliverableDetailFragment}
`;

const deliverableQuery = gql`
    query DeliverableQuery($id: ID!) {
        deliverable (id: $id) {
            id
            title
            includedInQuote
            additionalCost
            completed
            project {
                id
            }
            tasks {
                id
                title
                time
                type
                complete
                includedInQuote
                additionalCost
                description
                img
                importance
                customerRelated
            }
            description
        }
    }
`;

const taskQuery = gql`
    query TaskQuery($id: ID!) {
        task (id: $id) {
            id
            deliverable {
                id
            }
            title
            time
            type
            description
            includedInQuote
            additionalCost
            complete
            img
            importance
            customerRelated
        }
    }
`;

const createProjectMutation = gql`
  mutation CreateProject($input: CreateProjectInput) {
    Project: createProject(input: $input) {
      ...ProjectDetail
    }
  }
  ${ProjectDetailFragment}
`;

const updateProjectMutation = gql`
    mutation updateProject($input: UpdateProjectInput) {
        Project: updateProject(input: $input) {
            ...ProjectDetail
        }
    }
    ${ProjectDetailFragment}
`;


const createDeliverableMutation = gql`
    mutation CreateDeliverable($input: CreateDeliverableInput) {
        Deliverable: createDeliverable(input: $input) {
            ...DeliverableDetail
        }
    }
    ${DeliverableDetailFragment}
`;

const updateDeliverableMutation = gql`
    mutation updateDeliverable($input: UpdateDeliverableInput) {
        Deliverable: updateDeliverable(input: $input) {
            ...DeliverableDetail
        }
    }
    ${DeliverableDetailFragment}
`;

const createTaskMutation = gql`
    mutation CreateTask($input: CreateTaskInput) {
        Task: createTask(input: $input) {
            ...TaskDetail
        }
    }
    ${taskDetailFragment}
`;

const updateTaskMutation = gql`
    mutation updateTask($input: UpdateTaskInput) {
        Task: updateTask(input: $input) {
            ...TaskDetail
        }
    }
    ${taskDetailFragment}
`;

const removeTaskMutation = gql`
    mutation removeTask($input: RemoveTaskInput) {
        Task: removeTask(input: $input) {
            ...TaskDetail
        }
    }
    ${taskDetailFragment}
`;

const removeDeliverableMutation = gql`
    mutation removeDeliverable($input: RemoveDeliverableInput) {
        Task: removeDeliverable(input: $input) {
            ...DeliverableDetail
        }
    }
    ${DeliverableDetailFragment}
`;

const projectsQuery = gql`
  query ProjectsQuery {
    projects {
     ...ProjectDetail
    }
  }
  ${ProjectDetailFragment}
`;

export async function loadCompanies() {
    const { data } = await client.query({query: companiesQuery, fetchPolicy: 'no-cache'});
    return data.companies;
}

  export async function loadCompany(id) {
    const { data } = await client.query({query: companyQuery, variables: {id}})
    let jsondata = new jsonAddTotals(data)
    return jsondata.calcProjectsTotals().company;
}

export async function loadProjects() {
    const { data } = await client.query({query: projectsQuery, fetchPolicy: 'no-cache'});
    // return data.projects
    let jsondata = new jsonAddTotals(data)
    return jsondata.calcProjectsOnlyTotals().projects;
}

export async function loadProject(id) {
    const { data } = await client.query( {query: projectQuery, variables: {id} } );
    let jsondata = new jsonAddTotals(data)
    return jsondata.calcProjectTotals().project;
}

export async function loadDeliverable(id) {
    const { data } = await client.query( {query: deliverableQuery, variables: {id} } );
    let jsondata = new jsonAddTotals(data)
    return jsondata.calcDeliverableTotals().deliverable;
}

export async function loadTask(id) {
    const { data } = await client.query( {query: taskQuery, variables: {id} } );
    return data.task;
}

export async function createProject(input) {
    const { data } = await client.mutate({
        mutation: createProjectMutation,
        variables: {input},
        update: (cache, {data}) => {
            cache.writeQuery({
                query: projectQuery,
                variables: {id: data.Project.id},
                data
            })
        }
    });
    return data.Project;
}

export async function createDeliverable(input) {
    const { data } = await client.mutate({
        mutation: createDeliverableMutation,
        variables: {input},
        update: (cache, {data}) => {
            cache.writeQuery({
                query: deliverableQuery,
                variables: {id: data.Deliverable.id},
                data
            })
        }
    });
    return data.deliverable;
}

export async function createTask(input) {
    const { data } = await client.mutate({
        mutation: createTaskMutation,
        variables: {input},
        update: (cache, {data}) => {
            cache.writeQuery({
                query: taskQuery,
                variables: {id: data.Task.id},
                data
            })
        }
    });
    return data.task;
}

export async function updateTask(input) {
    console.log("start-updatetask", JSON.stringify(input))
    const { data } = await client.mutate({
        mutation: updateTaskMutation,
        variables: {input},
        update: (cache, {data}) => {
            console.log( "task data", JSON.stringify(data))
            cache.writeQuery({
                query: taskQuery,
                variables: {id: data.Task.id},
                data
            })
        }
    });
    return data.task;
}

export async function updateDeliverable(input) {
    console.log("start-updatedeliverable" )
    console.log("start-updatedeliverable", JSON.stringify(input) )
    const { data } = await client.mutate({
        mutation: updateDeliverableMutation,
        variables: {input},
        update: (cache, {data}) => {
            console.log( "deliverable data", JSON.stringify(data))
            cache.writeQuery({
                query: deliverableQuery,
                variables: {id: data.id},
                data
            })
        }
    });
    console.log("done")
    return data.deliverable;
}

export async function updateProject(input) {
    console.log("--", JSON.stringify(input))
    const { data } = await client.mutate({
        mutation: updateProjectMutation,
        variables: {input},
        update: (cache, {data}) => {
            console.log( "data", JSON.stringify(data))
            cache.writeQuery({
                query: deliverableQuery,
                variables: {id: data.Project.id},
                data
            })
        }
    });
    console.log("done")
    return data.deliverable;
}

export async function removeTask(input) {
    console.log("--", JSON.stringify(input))
    const { data } = await client.mutate({
        mutation: removeTaskMutation,
        variables: {input},
        update: (cache, {data}) => {
            console.log( "data", JSON.stringify(data))
            cache.writeQuery({
                query: taskQuery,
                variables: {id: data.Task.id},
                data
            })
        }
    });
    console.log("done")
    return data.task;
}

export async function removeDeliverable(input) {
    console.log("--", JSON.stringify(input))
    const { data } = await client.mutate({
        mutation: removeDeliverableMutation,
        variables: {input},
        update: (cache, {data}) => {
            console.log( "data", JSON.stringify(data))
            cache.writeQuery({
                query: deliverableQuery,
                variables: {id: data.Deliverable.id},
                data
            })
        }
    });
    console.log("done")
    return data.deliverable;
}
