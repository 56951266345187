import React from 'react'
import {connect} from 'react-redux';
import {loadTask} from "../../../requests";
import {Formik} from "formik";
import {deleteTask, saveTask} from "../../../actions";

class TaskEditComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            taskId: this.props.currentModals.modalProps.taskId,
            task: []
        };
    }

    async componentDidMount() {
        const aTask = await loadTask(this.state.taskId);
        this.setState({task: aTask});
        console.log("did mount                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ")
    }

    onDelete() {
        this.props.dispatch(
            deleteTask(
                this.state.taskId,
            )
        );
    }

    render() {

        if (this.state.task.length === 0) {
            return null
        }

        return (
            <div style={{width: '600px'}}>

                <Formik
                    initialValues={{
                        title: this.state.task.title,
                        description: this.state.task.description,
                        time: this.state.task.time,
                        complete: this.state.task.complete,
                        deliverableId: this.state.task.deliverable.id,
                        importance: this.state.task.importance,
                        customerRelated: this.state.task.customerRelated
                    }}

                    onSubmit={values => {
                        this.props.dispatch(
                            saveTask(
                                this.state.taskId,
                                values.deliverableId,
                                values.title,
                                values.description,
                                values.complete,
                                values.time,
                                values.importance,
                                values.customerRelated,
                            ));
                    }}

                    // validate={ values => {
                    //     let errors= {}
                    //     if (!values.title) {
                    //         errors.title = "please enter a name"
                    //     }
                    //     if (!values.duration) {
                    //         errors.duration= "please enter a duration"
                    //     }
                    //     return errors
                    // }}
                >

                    {
                        ({handleSubmit, handleChange, values, errors, handleBlur, touched}) => (
                            <form onSubmit={handleSubmit}>

                                <div className="form-group">
                                    <label htmlFor="exampleInputEmail1">Task Title</label>
                                    <input
                                        className="form-control"
                                        onChange={handleChange}
                                        value={values.title}
                                        type="text"
                                        name="title"
                                        placeholder="Enter your title"
                                        onBlur={handleBlur}
                                    />
                                    <small id="emailHelp" className="form-text text-muted">A nice descriptive title</small>
                                </div>

                                <div className="form-group">
                                    <label htmlFor="exampleInputEmail1">Description</label>
                                    <textarea
                                        className="form-control textarea"
                                        onChange={handleChange}
                                        value={values.description}
                                        name="description"
                                        placeholder="Enter your description"
                                        onBlur={handleBlur}
                                    />
                                    <small id="emailHelp" className="form-text text-muted">A nice description
                                    </small>
                                </div>

                                <div className="form-group">
                                    <label htmlFor="exampleInputEmail1">Time to complete</label>
                                    <input
                                        className="form-control"
                                        onChange={handleChange}
                                        value={values.time}
                                        type="text"
                                        name="time"
                                        placeholder="Enter your time"
                                        onBlur={handleBlur}
                                    />
                                    <small id="emailHelp" className="form-text text-muted">In days</small>
                                </div>

                                
                                <div className="form-group">
                                    <label htmlFor="exampleInputEmail1">Importance</label>
                                    <input
                                        className="form-control"
                                        onChange={handleChange}
                                        value={values.importance}
                                        type="text"
                                        name="importance"
                                        placeholder="Importance 1 to 5"
                                        onBlur={handleBlur}
                                    />
                                    <small id="emailHelp" className="form-text text-muted">A nice descriptive title</small>
                                </div>

                                <div className="form-group">
                                    <label htmlFor="exampleInputEmail1">Deliverible Id</label>
                                    <input
                                        className="form-control"
                                        onChange={handleChange}
                                        value={values.deliverableId}
                                        type="text"
                                        name="deliverableId"
                                        placeholder="Deliverable Id"
                                        onBlur={handleBlur}
                                    />
                                    <small id="emailHelp" className="form-text text-muted">Don't mess</small>
                                </div>

                                <div className="form-group row">
                                    <div className="col-sm-2">Completed</div>
                                    <div className="col-sm-10">
                                        <div className="form-check">
                                            <input
                                                name="complete"
                                                type="checkbox"
                                                className="form-check-input"
                                                onChange={handleChange}
                                                checked={values.complete}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <div className="col-sm-2">Customer Related</div>
                                    <div className="col-sm-10">
                                        <div className="form-check">
                                            <input
                                                name="customerRelated"
                                                type="checkbox"
                                                className="form-check-input"
                                                onChange={handleChange}
                                                checked={values.customerRelated}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-6">
                                        <button type="submit" className="btn btn-primary pull-right">Save</button>
                                        &nbsp;
                                        <button onClick={() => { this.props.onCancel() }} className="btn btn-secondary pull-right">Cancel</button>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="text-right">
                                            <button type="button" className="btn btn-danger" onClick={() => { this.onDelete() }}>Delete</button>
                                        </div>
                                    </div>
                                </div>

                                {
                                    errors.title &&
                                    <div style={{color: 'red'}}>
                                        {errors.title}
                                    </div>
                                }
                            </form>
                        )

                    }
                </Formik>

            </div>
        )

    };

}

const mapStateToProps = (state) => ({
    currentModals: state.modals
});


export default connect(mapStateToProps, null)(TaskEditComponent);
