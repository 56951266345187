import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { loadProject } from './requests';
import {Jumbotron, Button, Breadcrumb, BreadcrumbItem, Card, CardBody, CardTitle, CardText} from 'reactstrap';
import {DeliverableFullVisualList} from "./DeliverableFullVisualList";

export class ProjectFullVisualDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {Project: null};
    }

    async componentDidMount() {
        const {ProjectId} = this.props.match.params;
        const Project = await loadProject(ProjectId);
        this.setState({Project});
    }

    render() {
        const {Project} = this.state;
        if (!Project) {
            return null;
        }
        return (
            <div>

                <br/>
                <Breadcrumb>
                    <BreadcrumbItem><a href="/">Home</a></BreadcrumbItem>
                    <BreadcrumbItem><a href="/">Library</a></BreadcrumbItem>
                    <BreadcrumbItem active>Data</BreadcrumbItem>
                </Breadcrumb>

                <div className="row">
                    <div className="col-sm-8">
                        <Jumbotron>
                            <div className="row">
                                <div className="col-sm-10">
                                    <h2 className="display-5">Project: {Project.title}</h2>
                                    <Link to={`/companies/${Project.company.id}`}>{Project.company.name}</Link>
                                </div>
                                <div className="col-sm-2">
                                    <i className="fas fa-tasks fa-3x fa-pull-right fa-border"></i>
                                </div>
                            </div>
                            <br/>
                            <div>
                                {Project.description}
                            </div>
                            <br/>
                        </Jumbotron>
                        <h3>Deliverables:</h3>
                        <br/>
                        <p>
                        </p>
                    </div>
                    <div className="col-sm-4">
                        <Card>
                            <CardBody>
                                <CardTitle>Menu</CardTitle>
                                <CardText>
                                    <Link to={`/Projects/${Project.id}`}>
                                        <Button type="button">
                                            Summary Project Details
                                        </Button>
                                    </Link><br/><br/>
                                    <Link to={`/ProjectsFullDetails/${Project.id}`}>
                                        <Button type="button">
                                            Full Project Details
                                        </Button>
                                    </Link><br/><br/>
                                    <Link to={`/ProjectsFullVisualDetails/${Project.id}`}>
                                        <Button type="button">
                                            Visual Project Details
                                        </Button>
                                    </Link><br/><br/>
                                </CardText>
                            </CardBody>
                        </Card>
                        <br/>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12">
                        <DeliverableFullVisualList Deliverables={Project.deliverables}/>
                    </div>
                </div>
                </div>
       );
    }
}
