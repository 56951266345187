import React, {Component, Fragment} from 'react';
import HighlightText from "./HighlightText";

export class DeliverableListSummary extends Component {

    renderDeliverable(Deliverable) {
        const title = Deliverable.title;
        //const workLeft = Deliverable.total - Deliverable.totalcompleted;
        //const outstanding = `${workLeft.toFixed(1)}` + ` days `

        if (this.props.currentFilters.data[0]["hideCompleted"]) {
            if ((Deliverable.totalcompleted - Deliverable.total) === 0) {
                return null
            }
        }

        return (
            <Fragment>
                <tr key={Deliverable.id} className="text-small bg-white">
                    <th colSpan="5">
                        <a href={`/ProjectsFullDetails/${this.props.project.id}`} className="d-flex align-items-center">
                        <span className="text-body">
                            <HighlightText showCheck={true} showText={true}
                                           completed={(Deliverable.totalcompleted - Deliverable.total) === 0}>{title}</HighlightText>
                        </span>
                        </a>
                    </th>
                    <td align="right">
                        {(Deliverable.totalcompleted - Deliverable.total) !== 0 &&
                        <div>
                            {(Deliverable.total - Deliverable.totalcompleted).toFixed(1)}
                        </div>
                        }
                    </td>
                    <td></td>
                </tr>

            </Fragment>
        );
    }

    render() {
        const {project} = this.props;
        return (
            <Fragment>
                {project.deliverables &&
                project.deliverables.map(this.renderDeliverable.bind(this))
                }
            </Fragment>
        );
    }

}
